import { Star } from "@mui/icons-material";
import { Rating } from "@mui/material";
import { useCallback, useState } from "react";
import { Trash } from "src/components/Icons";
import { ReviewComponents } from "src/components/ReviewComponents";
import { FlagModal, Modal } from "src/components/ModalComp";
import { checkPermissions } from "src/components/PermissionRestrictorComp";
import { PERMISSION } from "src/helpers/Constant";
import { useAuth } from "src/hooks/useAuth";
import {
  handleNotification,
  SuccessNotification,
} from "src/helpers/helperFunction";
import { useUpdateBranchReviewStatusMutation } from "src/api/manageBranchAPISlice";
import { ErrorType } from "src/helpers/alias";

export default function BranchReview(props: {
  branchId: string;
  ratings: {
    comment: string;
    rating: number;
    _id: string;
    user: {
      firstName: string;
      lastName: string;
      location: { state: string };
    };
  }[];
}) {
  const auth = useAuth();
  const [changeStatus, changeStatusResult] =
    useUpdateBranchReviewStatusMutation();
  const occurrences = useCallback(
    (num: number) => {
      const ratings = props?.ratings;

      const RatingAverage = ratings?.reduce(
        (
          acc: number,
          cur: { rating: number },
          index: any,
          array: string | any[]
        ): any => Number((acc += cur.rating / array.length).toFixed(1)),
        0
      );
      const occurr = ratings?.reduce((acc: any, curr: any) => {
        return (
          acc[curr?.rating] ? ++acc[curr?.rating] : (acc[curr?.rating] = 1), acc
        );
      }, {});

      let totalCalStar = Math.ceil((occurr[num] * num * RatingAverage) / 100);

      return {
        RatingAverage,
        totalCalStar,
      };
    },
    [props]
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedReview, setselectedReview] = useState<any>();

  async function archiveReview(rating: any, updateTo: any) {
    try {
      const response = await changeStatus({
        branch: props.branchId,
        params: { ratingId: rating.id },
        body: { status: updateTo },
      });

      if (response) {
        setShowDeleteModal(false);
        SuccessNotification("Status Updated");
        return;
      }
    } catch (error: ErrorType | any) {
      handleNotification(error);
    }
  }
  console.log(props?.ratings);
  // {{localhostURL}}/api/v1/system-admin/station/branch/ratings/65f2de732f79be1443ac0aa6?ratingId=665d7f1712c48bac42564b11
  return (
    <section>
      <article className="flex flex-cols lg:flex-row gap-4  bg-white rounded-lg mt-10 h-screen">
        <div className="lg:basis-[65%] h-fit grid grid-cols-2 mt-10 gap-4 px-4">
          {props.ratings?.map((dt, i) => (
            <div className="h-fit mt-6 border px-2 py-4 border-[#636685] rounded-xl">
              <div
                key={dt?._id}
                className="flex w-full justify-between items-center px-6 text-start text-[#1E1E1E] text-[12px]"
              >
                <div>
                  <h4>{`${dt?.user?.firstName} ${dt?.user?.lastName}`}</h4>
                  <p className="text-[#636685]">{dt?.user?.location?.state}</p>
                </div>
                <div>
                  <Rating
                    name="customized-10"
                    size="small"
                    disabled
                    defaultValue={dt.rating}
                  />
                </div>
              </div>
              <div>
                <p className="text-justify text-[12px] px-6 leading-[20px] mt-3">
                  {dt?.comment}
                </p>
              </div>
              {checkPermissions(auth?.user?.role || "", [
                PERMISSION.SYSTEM_ADMIN,
              ]) && (
                <div
                  className=" pl-6 pt-3 rounded-lg bg-white cursor-pointer"
                  onClick={() => {
                    setselectedReview(dt);
                    setShowDeleteModal(true);
                    return;
                  }}
                >
                  <Trash />
                </div>
              )}
            </div>
          ))}
        </div>

        {/* RATING REVIEWS */}
        <div className="lg:basis-[35%] h-fit pt-4 pb-10">
          <div className="px-6 h-[25%] mt-5 flex flex-col">
            <div className="my-4 text-[#1E1E1E]">
              <h3 className="text-[16px] text-black">Ratings Summary</h3>
            </div>
            <ReviewComponents review={(num: number) => occurrences(num)} />

            {props?.ratings?.length > 0 ? (
              <div className="self-start mt-4">
                <div className="flex items-center justify-center">
                  <small className="text-[24px]">
                    {occurrences(0)?.RatingAverage || ""}
                  </small>
                  <small className="">
                    <Star className="text-[#FFB400] text-center text-[24px] align-middle" />
                  </small>
                </div>
                <p className="text-[12px]">
                  {props?.ratings?.length}{" "}
                  {props?.ratings?.length > 1 ? "Reviews" : "Review"}
                </p>
              </div>
            ) : null}
          </div>
        </div>
        {showDeleteModal && (
          <Modal>
            <FlagModal
              info="Are you sure you want to delete?"
              onClose={() => setShowDeleteModal(false)}
              showModal={changeStatusResult.isLoading}
              onConfirmation={() =>
                archiveReview(
                  selectedReview,
                  selectedReview.status === "visible" ? "invisible" : "visible"
                )
              }
            />
          </Modal>
        )}
      </article>
    </section>
  );
}
