import { Delete, Edit, HighlightOffOutlined } from "@mui/icons-material";
import { useFormik } from "formik";
import React, { ChangeEvent, Key, useCallback, useMemo, useState } from "react";
import {
  useAddNewFeedsMutation,
  useDeleteFeedsMutation,
  useFetchAllFeedsQuery,
  useUpdateFeedMutation,
  //   useUpdateFeedMutation,
} from "src/api/feedsApiSlice";
import { Button } from "src/components/Button";
import { Lines } from "src/components/Icons";
import Image from "src/components/Image";
import { FormInput, SearchInput, TextArea } from "src/components/inputs";
import { LoaderContainer } from "src/components/LoaderContainer";
import { FlagModal, Modal } from "src/components/ModalComp";
import { ShowVideoAndImage } from "src/components/RenderImagePreview";
import { Upload } from "src/components/Upload";
import {
  convert2base64,
  formatDateToSocialMediaStandard,
  handleDateFormat,
  handleNotification,
  SuccessNotification,
} from "src/helpers/helperFunction";
import { useDebounce } from "src/hooks/useDebounce";
import * as Yup from "yup";
import Tooltip from "@mui/material/Tooltip";
import { checkPermissions } from "src/components/PermissionRestrictorComp";
import { useAuth } from "src/hooks/useAuth";
import { PERMISSION } from "src/helpers/Constant";

export default function Feeds() {
  const auth = useAuth();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<{
    action: boolean;
    id: string | null;
  }>({
    action: false,
    id: null,
  });
  const [showEditModal, setShowEditModal] = useState<{
    action: boolean;
    feed: any;
  }>({
    action: false,
    feed: null,
  });
  const [selectedFeeds, setSelectedFeeds] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { debouncedValue: debouncedSearchQuery } = useDebounce(
    searchQuery,
    700
  );
  const feedsResult = useFetchAllFeedsQuery({
    ...(searchQuery && {
      search: debouncedSearchQuery,
    }),
  });

  const [deleteFeed, deleteFeedsResult] = useDeleteFeedsMutation();

  const handleApiResponse: any = useMemo(
    () => feedsResult?.currentData?.feeds,
    [feedsResult]
  );

  async function DeleteFeeds(ids: string[]) {
    try {
      if (!checkPermissions(auth?.user?.role || "", [PERMISSION.SYSTEM_ADMIN]))
        return;
      for (const id of ids) {
        await deleteFeed(id).unwrap();
      }
      setShowDeleteModal((prevState) => {
        return { ...prevState, action: false, id: null };
      });
      SuccessNotification("Feeds deleted successfully");
    } catch (error: any) {
      handleNotification(error);
    }
  }

  const handleEditModalOpen = (feed: any) => {
    setShowEditModal({ action: true, feed });
  };

  const handleSelectFeed = (id: string) => {
    setSelectedFeeds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((feedId) => feedId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDeleteSelectedFeeds = () => {
    setShowDeleteModal({ action: true, id: null });
  };

  const isSelected = (id: string) => selectedFeeds.includes(id);

  return (
    <section>
      <article className="rounded-lg mt-10 h-fit">
        <div className="flex items-center justify-start w-full gap-3">
          <SearchInput
            name="notification-search"
            placeholder="Search feeds"
            value={searchQuery}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const target = e.target;
              setSearchQuery(target.value);
            }}
          />
          <div className="w-[189px] h-11 ">
            <Button
              text="Add Feeds"
              className="h-full font-bold text-white rounded-lg w-full bg-[#002E66] flex items-center justify-start pl-4"
              type="button"
              showIcon={true}
              onClick={() => setShowAddModal(true)}
            />
          </div>
          {checkPermissions(auth?.user?.role || "", [
            PERMISSION.SYSTEM_ADMIN,
          ]) &&
            selectedFeeds.length > 0 && (
              <Button
                text="Delete Selected"
                className="h-11 font-bold text-white rounded-lg bg-red-500 px-2 py-1 hover:bg-red-700 flex items-center justify-center"
                type="button"
                showIcon={false}
                onClick={handleDeleteSelectedFeeds}
              />
            )}
        </div>
        <LoaderContainer data={feedsResult || deleteFeedsResult}>
          <div className="w-full h-fit grid grid-cols-1 md:grid-cols-2 mt-10 gap-3">
            {handleApiResponse?.data?.map(
              (
                _v: {
                  id: string;
                  createdAt: Date;
                  title: string;
                  body: string;
                  media: { url: string }[];
                },
                i: Key
              ) => {
                return (
                  <div
                    key={_v.id}
                    className="h-fit border px-2 py-4 bg-white border-[#002e66] rounded-xl"
                  >
                    <div className="flex justify-end w-full pr-2 capitalize text-sm">
                      {handleDateFormat(`${_v?.createdAt}`)}
                    </div>
                    <div className="flex w-full justify-between items-center px-6 text-start text-[#1E1E1E] text-[12px] mt-4">
                      <h4 className="font-bold text-xl">{_v?.title}</h4>
                      <div className="flex items-center space-x-2">
                        <Tooltip title="Edit" arrow>
                          <Edit
                            fontSize="medium"
                            className="cursor-pointer text-blue-500 hover:text-blue-700"
                            onClick={() => handleEditModalOpen(_v)}
                          />
                        </Tooltip>
                        {checkPermissions(auth?.user?.role || "", [
                          PERMISSION.SYSTEM_ADMIN,
                        ]) && (
                          <>
                            <Tooltip title="Delete" arrow>
                              <Delete
                                fontSize="medium"
                                className="cursor-pointer text-red-500 hv:text-red-700"
                                onClick={() =>
                                  setShowDeleteModal({
                                    action: true,
                                    id: _v?.id,
                                  })
                                }
                              />
                            </Tooltip>
                            <input
                              type="checkbox"
                              checked={isSelected(_v.id)}
                              onChange={() => handleSelectFeed(_v.id)}
                            />{" "}
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <p className="text-justify text-sm px-6 leading-[20px] mt-3">
                        {_v?.body}
                      </p>
                    </div>
                    <div className="h-56 w-full px-2 mt-4">
                      <Image
                        image={
                          _v?.media[0]?.url
                            ? _v?.media[0]?.url
                            : "https://static.vecteezy.com/packs/media/photo/hero-800px-9fbe463f.jpg"
                        }
                        width={100}
                        height={100}
                        styles="w-full h-full object-cover rounded-lg"
                      />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </LoaderContainer>
        {showDeleteModal.action && (
          <Modal>
            <FlagModal
              info="Are you sure you want to delete?"
              showModal={deleteFeedsResult.isLoading}
              onClose={() => setShowDeleteModal({ action: false, id: null })}
              onConfirmation={() =>
                DeleteFeeds(
                  showDeleteModal.id ? [showDeleteModal.id] : selectedFeeds
                )
              }
            />
          </Modal>
        )}

        {showEditModal.action && (
          <Modal>
            <div className="absolute w-full h-full right-0 top-0 bg-[rgba(0,0,0,0.5)] flex justify-center items-center">
              <div className="w-[50%] max-w-[511px] h-fit flex flex-col justify-center rounded-[20px] pb-10 bg-white">
                <div className="w-full h-12 px-10 pt-2 pb-2 mt-2 font-bold text-xl text-[#002E66] flex justify-between items-center">
                  <h1>Edit Feeds</h1>
                  <button
                    onClick={() =>
                      setShowEditModal((prevState) => ({
                        ...prevState,
                        action: false,
                      }))
                    }
                  >
                    <HighlightOffOutlined
                      fontSize="large"
                      className="text-black cursor-pointer"
                    />
                  </button>
                </div>
                <div className="w-full">
                  <Lines />
                </div>
                <EditFeedsModal
                  id={showEditModal.feed.id}
                  feed={showEditModal.feed}
                  close={() =>
                    setShowEditModal((prevState) => ({
                      ...prevState,
                      action: false,
                    }))
                  }
                />
              </div>
            </div>
          </Modal>
        )}

        {showAddModal ? (
          <Modal>
            <div className="absolute w-full h-full right-0 top-0 bg-[rgba(0,0,0,0.5)] flex justify-center items-center">
              <div className="w-[50%] max-w-[511px] h-fit pt-6 max-h-[70vh] flex flex-col justify-center rounded-lg pb-10 bg-white">
                <div className="w-full h-12 px-10 pt-2 pb-2 mt-2 font-bold text-xl text-[#002E66] flex justify-between items-center">
                  <h1>Create Feeds</h1>
                  <button
                    onClick={() => setShowAddModal(false)}
                    disabled={false}
                  >
                    <HighlightOffOutlined
                      fontSize="large"
                      className="text-black cursor-pointer"
                    />
                  </button>
                </div>
                <div className="w-full  ">
                  <Lines />
                </div>
                <AddNewSelfHelp close={() => setShowAddModal(false)} />
              </div>
            </div>
          </Modal>
        ) : null}
      </article>
    </section>
  );
}

// ADD FEEDS
const AddNewFeedsValidation = Yup.object({
  title: Yup.string().label("Title").required(),
  body: Yup.string().label("body").required(),
  media: Yup.array().of(Yup.string().notRequired()),
});

export type newFeedsValidation = Yup.InferType<typeof AddNewFeedsValidation>;

const AddNewSelfHelp = ({ close }: { close: () => void }) => {
  const [addNewFeeds, addNewFeedsResult] = useAddNewFeedsMutation();

  async function SubmitNewFeeds(values: newFeedsValidation) {
    try {
      const response = await addNewFeeds(values).unwrap();
      if (response) {
        close();
      }
      SuccessNotification(response?.status);
    } catch (error: any) {
      close();
      handleNotification(error);
    }
  }

  const Formik = useFormik<newFeedsValidation>({
    initialValues: {
      title: "",
      body: "",
      media: [],
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: AddNewFeedsValidation,
    onSubmit: (values) => {
      SubmitNewFeeds(values);
    },
  });
  const styles =
    "h-[38px] py-6 rounded-lg w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-[#D9D9D9]";
  const labelStyles =
    "block mb-[6px] text-black text-start font-normal text-[14px] text-black ml-5 my-6";

  const FormData = [
    {
      id: "body",
      name: "Description",
      styles: `${styles} ${
        Formik.errors.body && Formik.touched.body
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.body,
      onBlur: Formik.handleBlur,
      disabled: addNewFeedsResult?.isLoading,
      error: Formik.errors.body,
      touched: Formik.touched.body,
    },
  ];

  const uploadSelfImage = useCallback(
    async (e: any) => {
      const image = Formik.values?.media || [];
      const file = e.target.files[0];
      if (file.type === "video/mp4") {
        return;
      }

      if (image?.length > 0) {
        return;
      }
      Formik.setFieldValue("media", [...image, await convert2base64(file)]);
    },
    [Formik]
  );

  const removeImage = useCallback(
    (id: number | string) => {
      const data = Formik.values.media?.filter(
        (_, i: string | number) => i !== id
      );
      Formik.setFieldValue("media", data);
    },

    [Formik]
  );

  return (
    <section className="overflow-y-scroll">
      <form
        onSubmit={Formik.handleSubmit}
        className=" flex flex-col justify-center items-center px-4 h-fit w-full mx-auto"
      >
        <div className="grid grid-cols-1 w-full gap-x-2 content-center">
          <FormInput
            id="title"
            name="Title"
            type="text"
            styles={`${styles} ${
              Formik.errors.title && Formik.touched.title
                ? "border-red-500"
                : "border-gray-300"
            }`}
            labelStyles={labelStyles}
            onChange={Formik.handleChange}
            value={Formik.values.title}
            onBlur={Formik.handleBlur}
            disabled={addNewFeedsResult?.isLoading}
            error={Formik.errors.title}
            touched={Formik.touched.title}
          />
        </div>

        <TextArea {...FormData[0]} />

        <div className="flex-col w-full items-center justify-between overflow-x-auto ">
          <ShowVideoAndImage
            media={Formik.values?.media || []}
            type={"Image"}
            removeImage={(id) => removeImage(id)}
          />
          <div className="w-full h-24 mt-4">
            <Upload
              name="avatar"
              text="Click to upload image "
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                uploadSelfImage(e);
              }}
            />
          </div>
        </div>

        <Button
          text={"Submit"}
          disabled={addNewFeedsResult?.isLoading}
          showModal={addNewFeedsResult?.isLoading}
          className="h-11 mt-5 w-full font-bold text-white rounded-lg px-5 bg-[#002E66] flex items-center flex-row justify-center"
          type="submit"
        />
      </form>
    </section>
  );
};

// EDIT FEEDS
const EditFeedsValidation = Yup.object({
  title: Yup.string().label("Title").required(),
  body: Yup.string().label("Body").required(),
  media: Yup.array().of(Yup.string().notRequired()),
});
export type EditFeedsValidationType = Yup.InferType<typeof EditFeedsValidation>;

const EditFeedsModal = ({
  id,
  close,
  feed,
}: {
  id: string;
  close: () => void;
  feed: any;
}) => {
  console.log(feed);
  const [updateFeed, updateFeedsResult] = useUpdateFeedMutation();

  async function SubmitEditedFeeds(values: EditFeedsValidationType) {
    try {
      const response = await updateFeed({
        id: feed.id,
        body: values,
      }).unwrap();
      if (response) {
        close();
        SuccessNotification(response?.status);
      }
    } catch (error: any) {
      close();
      handleNotification(error);
    }
  }

  const Formik = useFormik<EditFeedsValidationType>({
    initialValues: {
      title: feed?.title,
      body: feed?.body,
      media: [],
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: EditFeedsValidation,
    onSubmit: (values) => {
      SubmitEditedFeeds(values);
    },
  });

  const styles =
    "h-[38px] py-6 rounded-lg w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-[#D9D9D9]";
  const labelStyles =
    "block mb-[6px] text-black text-start font-normal text-[14px] text-black ml-5 my-6";

  const uploadSelfImage = useCallback(
    async (e: any) => {
      const image = Formik.values?.media || [];
      const file = e.target.files[0];
      if (file.type === "video/mp4") {
        return;
      }

      if (image?.length > 0) {
        return;
      }
      Formik.setFieldValue("media", [...image, await convert2base64(file)]);
    },
    [Formik]
  );

  const removeImage = useCallback(
    (id: number | string) => {
      const data = Formik.values.media?.filter(
        (_, i: string | number) => i !== id
      );
      Formik.setFieldValue("media", data);
    },

    [Formik]
  );

  return (
    <form
      onSubmit={Formik.handleSubmit}
      className=" flex flex-col justify-center items-center px-4 h-fit w-full mx-auto"
    >
      <div className="grid grid-cols-1 w-full gap-x-2 content-center">
        <FormInput
          id="title"
          name="Title"
          type="text"
          styles={`${styles} ${
            Formik.errors.title && Formik.touched.title
              ? "border-red-500"
              : "border-gray-300"
          }`}
          labelStyles={labelStyles}
          onChange={Formik.handleChange}
          value={Formik.values.title}
          onBlur={Formik.handleBlur}
          disabled={updateFeedsResult?.isLoading}
          error={Formik.errors.title}
          touched={Formik.touched.title}
        />
      </div>

      <TextArea
        id="body"
        name="Body"
        styles={`${styles} ${
          Formik.errors.body && Formik.touched.body
            ? "border-red-500"
            : "border-gray-300"
        }`}
        labelStyles={labelStyles}
        onChange={Formik.handleChange}
        value={Formik.values.body}
        onBlur={Formik.handleBlur}
        disabled={updateFeedsResult?.isLoading}
        error={Formik.errors.body}
        touched={Formik.touched.body}
      />

      <div className="flex-col w-full items-center justify-between overflow-x-auto ">
        <ShowVideoAndImage
          media={Formik.values?.media || []}
          type={"Image"}
          removeImage={(id) => removeImage(id)}
        />
        <div className="w-full h-24 mt-4">
          <Upload
            name="avatar"
            text="Click to upload image "
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              uploadSelfImage(e);
            }}
          />
        </div>
      </div>

      <Button
        text={"Update"}
        disabled={updateFeedsResult?.isLoading}
        showModal={updateFeedsResult?.isLoading}
        className="h-11 mt-5 w-full font-bold text-white rounded-lg px-5 bg-[#002E66] flex items-center flex-row justify-center"
        type="submit"
      />
    </form>
  );
};
