import React, { useState } from "react";
import { useChangeStatusMutation } from "src/api/manageBranchAPISlice";
import {
  handleNotification,
  splitByUpperCase,
  SuccessNotification,
} from "src/helpers/helperFunction";
import useCustomLocation from "src/hooks/useCustomLocation";
import { Button } from "./Button";
import Image from "./Image";
import { FlagModal, Modal } from "./ModalComp";
import { Avatar } from "@mui/material";

interface ProfileType {
  data: any;
  showImage?: boolean | undefined;
  imageURL?: string;
  showBanner?: boolean | undefined;
  showHeader?: boolean;
  showSecondButton?: boolean;
  secondOnClick?: () => void;
  header?: string;
  secondButtonText?: string;
  buttonText?: string;
  fn?: () => void;
  flag?: boolean;
  status?: string;
  showButton?: boolean;
  onClick?: () => void;
  onClose?: any;
  makeRequest?: () => void;
  handleModal?: () => void;
  apiResult?: { isLoading: boolean };
  showModal?: boolean;
  suspend?: string;
}

export default function ProfileCard(props: ProfileType) {
  const { routePath } = useCustomLocation();
  return (
    <div className=" h-fit bg-white shadow-lg rounded-lg text-[14px] py-6 ">
      {props.showHeader ? (
        <div className="my-3">
          <h1 className="text-bold text-lg text-black text-center">
            {props.header}
          </h1>
        </div>
      ) : null}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-y-10 py-4 md:gap-x-2 text-start px-4 lg:px-16">
        {!!props.showBanner ? (
          <div>
            <div className=" text-start ">
              <h2 className="text-black">Profile Picture</h2>
              <span className="block bg-[#737587] h-0.5 w-20 my-1.5 rounded-lg"></span>
              <div className="h-20 w-20 mt-6">
                {/* <Image
                  image={props.imageURL || ""}
                  width={"100%"}
                  height={"100%"}
                  styles="object-cover rounded-full"
                /> */}
                <Avatar
                  alt={`${
                    props?.data?.firstName + " " + props?.data?.lastName || ""
                  }`}
                  src={`${props.imageURL || ""}`}
                  sx={{ width: "100%", height: "100%" }}
                >
                  {!props.imageURL
                    ? `${props?.data?.firstName?.[0] || ""} ${
                        props?.data?.lastName?.[0] || ""
                      }`
                    : ""}
                </Avatar>
              </div>
            </div>
          </div>
        ) : null}
        {Object.keys(props?.data)?.map((dt, i) => {
          return (
            <div key={i + 1}>
              <h2 className="text-black capitalize">{splitByUpperCase(dt)}</h2>
              <span className="block bg-[#737587] h-0.5 w-20 my-1.5 rounded-lg capitalize"></span>
              <h2 className="text-[#002E66] capitalize">{props?.data[dt]}</h2>
            </div>
          );
        })}
      </div>
      {props.showButton || props.showSecondButton ? (
        <div className="h-11  mt-4 w-full justify-end flex items-end px-4 lg:px-16 gap-3">
          {props.showButton ? (
            <Button
              text={props?.buttonText || "Update branch details"}
              type="button"
              showIcon={false}
              onClick={props.onClick}
            />
          ) : null}
          {props.showSecondButton ? (
            <Button
              text={props?.secondButtonText || ""}
              type="button"
              showIcon={false}
              onClick={props?.secondOnClick}
            />
          ) : null}
        </div>
      ) : null}
      {!!props.showImage ? (
        <div>
          <div className="py-4 text-start px-4 lg:px-16">
            <h2 className="text-black">Profile Picture</h2>
            <span className="block bg-[#737587] h-0.5 w-20 my-1.5 rounded-lg"></span>
            <div className="w-36 h-36 rounded-full mt-6">
              {props?.imageURL ? (
                <Image
                  image={props.imageURL || ""}
                  width={"100%"}
                  height={"100%"}
                  styles="object-cover h-full rounded-full"
                />
              ) : (
                <div className="w-[90px] h-[90px] rounded-full self-center object-fit bg-[#D9D9D9] flex justify-center items-center">
                  <h1 className="text-xl text-center font-extrabold">
                    {props?.data?.firstName?.charAt(0) || ""}
                    {props?.data?.lastName?.charAt(0) || ""}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
