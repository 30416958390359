import { Fragment, useEffect, useMemo } from "react";
import { CardButton } from "src/components/Card";
import ProfileCard from "src/components/ProfileCard";
import useCustomLocation from "src/hooks/useCustomLocation";
import React, { useState } from "react";
import AdminProfile from "src/assets/img/AdminProfile.svg";
import { cardBtnType } from "src/helpers/alias";
import { APP_ROUTE } from "src/helpers/Constant";
import {
  useGetAdminQuery,
  useUpdateAdminMutation,
} from "src/api/setttingsApislice";
import { useAuth } from "src/hooks/useAuth";
import { SuccessNotification } from "src/helpers/helperFunction";
import { toast } from "react-toastify";
import { FlagModal, Modal } from "src/components/ModalComp";
import { useNavigate } from "react-router-dom";
import ProfileEditCard from "src/components/EditProfileCard";
import { useAppDispatch } from "src/hooks/reduxhooks";
import { setUser } from "src/features/auth/authSlice";

const HQData: cardBtnType[] = [
  {
    id: 1,
    icon: AdminProfile,
    name: "Profile",
    link: APP_ROUTE.ADMIN_PROFILE,
  },
  {
    id: 2,
    icon: AdminProfile,
    name: "Edit Admin",
    link: APP_ROUTE.ADMIN_PROFILE_EDIT,
  },
];

const SingleAdmin = () => {
  const [cardName, setCardName] = useState<string>("profile");
  const [showModal, setShowModal] = useState(false);
  const [userId, setuserId] = useState(false);
  const { routePath } = useCustomLocation();
  const { id, ...rest } = routePath?.data;
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      setuserId(user.id);
    }
  }, []);
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useGetAdminQuery(userId, { skip: !userId });
  const adminResult = useGetAdminQuery(id, { skip: !id });
  const [updateAdminMutation, updateResult] = useUpdateAdminMutation();

  const handledAPIResponse = useMemo(() => {
    const hqProfile = adminResult?.data?.data;

    return {
      profile: {
        firstName: hqProfile?.firstName,
        lastName: hqProfile?.lastName,
        email: hqProfile?.email,
        role: hqProfile?.role,
        phoneNumber: hqProfile?.phoneNumber,
        gender: hqProfile?.gender,
        status: hqProfile?.accountStatus?.status,
      },
      id: hqProfile?.id,
      avatar: hqProfile?.avatar,
    };
  }, [adminResult]);
  const dispatch = useAppDispatch();
  function handleModal() {
    setShowModal((prevState) => !prevState);
  }
  function onLogOut() {
    // dispatch(logOut());
    navigate("/login");
  }
  async function makeRequest() {
    try {
      await updateAdminMutation({
        accountStatus: {
          status:
            handledAPIResponse?.profile?.status === "pending"
              ? "confirmed"
              : handledAPIResponse?.profile?.status === "deactivated"
              ? "confirmed"
              : "deactivated",
          reason: "suspended",
        },
        id,
      });
      const response = await currentUser.refetch();

      dispatch(setUser(response.data.data));
      SuccessNotification("Status updated");
    } catch (error: any) {
      // console.log("ksdkofhsdif", error);
      toast.error(error?.message as any) as any;
    }
    setShowModal((prevState) => !prevState);
  }

  async function updateAdmin(values: any) {
    try {
      await updateAdminMutation({ ...values, id });

      SuccessNotification("Admin updated");
    } catch (error: any) {
      toast.error(error?.message as any) as any;
    }
  }

  const handleUserEdit = (data: any) => {
    return (
      {
        status: data?.status,
      } || {}
    );
  };

  return (
    <section>
      <article>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4  py-3">
          <>
            {HQData.map((dt) => (
              <Fragment key={dt.id}>
                <CardButton
                  name={dt.name}
                  icon={dt.icon}
                  link={dt.link}
                  activeBtn={cardName}
                  onClick={() => setCardName(dt.name.toLowerCase())}
                  height={"70px"}
                />
              </Fragment>
            ))}
          </>
        </div>

        {cardName === "profile" ? (
          <ProfileCard
            showBanner={false}
            data={handledAPIResponse?.profile || {}}
            flag={user?.id !== handledAPIResponse?.id}
            handleModal={handleModal}
            suspend={
              handledAPIResponse?.profile?.status === "pending"
                ? "Activate"
                : handledAPIResponse?.profile?.status === "confirmed"
                ? "Suspend"
                : "Unsuspend"
            }
          />
        ) : null}

        {cardName === "edit admin" ? (
          <ProfileEditCard
            secondButtonText={"Cancel"}
            showSecondButton={false}
            showBanner={false}
            data={handleUserEdit(handledAPIResponse?.profile) || {}}
            showImage={false}
            // flag={true}
            makeRequest={handleModal}
            showModal={updateResult.isLoading}
            showButton={true}
            handleModal={handleModal}
          />
        ) : null}
      </article>
      {showModal && (
        <Modal>
          <FlagModal
            info={`Are you sure you want to ${
              handledAPIResponse?.profile?.status === "confirmed"
                ? `Suspend ${handledAPIResponse?.profile?.firstName}`
                : `Unsuspend ${handledAPIResponse?.profile?.firstName}`
            }?`}
            onClose={handleModal}
            onConfirmation={makeRequest}
            showModal={updateResult?.isLoading}
          />
        </Modal>
      )}
    </section>
  );
};

export default SingleAdmin;
