import InputComp from "src/components/InputComp";
import { LogoUpload } from "src/components/Upload";
import { ImagePreview } from "../CreateDepot";
import { SelectInput } from "src/components/inputs";
import { states } from "src/helpers/data";

export const DepotInformation = ({
	formik,
	uploadLogo,
	hidden,
}: {
	formik: any;
	hidden?: boolean;
	uploadLogo: (arg: any) => void;
}) => {
	return (
		<div>
			<div className="mx-auto w-full flex flex-col justify-center items-center">
				{!hidden ? (
					<LogoUpload name="logo" text="Choose logo" onChange={uploadLogo} />
				) : null}

				{formik.values?.depot.logo ? (
					<ImagePreview image={formik.values?.depot.logo} />
				) : null}
			</div>
			<InputComp
				label="Depot name"
				error={formik.errors.depot?.name}
				placeholder="Name"
				{...formik.getFieldProps("depot.name")}
			/>
			<InputComp
				label="Email"
				error={formik.errors.depot?.email}
				placeholder="Email"
				{...formik.getFieldProps("depot.email")}
			/>
			<InputComp
				label="Phone number"
				error={formik.errors.depot?.phoneNumber}
				placeholder="Phone number"
				{...formik.getFieldProps("depot.phoneNumber")}
			/>
			<InputComp
				label="Address"
				error={formik.errors.depot?.address}
				placeholder="Enter Address"
				{...formik.getFieldProps("depot.address")}
			/>

			<SelectInput
				id="state"
				data={states}
				labelStyles="block mb-[6px]  text-start font-normal text-[14px] text-black ml-5 my-6"
				name="Select state"
				onChange={(e) => formik.setFieldValue("depot.state", e.target.value)}
				value={formik.values.depot.state}
			/>
		</div>
	);
};
