import React, { useState, ChangeEventHandler } from "react";
import { Button } from "./Button";
import Image from "./Image";
import { FormInput, SelectInput } from "./inputs";
import { SELECT_OPTIONS } from "src/helpers/Constant";
import { DateRange, DateRangeComp, TimeInput } from "./DateComp";

const PROFILE_ATTRIBUTES: any = {
  email: { label: "Email", type: "text" },
  name: { label: "Name", type: "text" },
  closingTime: { label: "Closing Time", type: "time" },
  openingTime: { label: "Opening Time", type: "time" },
  pumpCount: { label: "Pump Count", type: "text" },
  address: { label: "Address", type: "text" },
  firstName: { label: "First Name", type: "text" },
  latitude: { label: "Latitude", type: "text" },
  lga: { label: "Lga", type: "text" },
  longitude: { label: "Longitude", type: "text" },
  lastName: { label: "Last Name", type: "text" },
  residentialAddress: { label: "Residential Address", type: "text" },
  phoneNumber: { label: "Phone Number", type: "text" },
  status: {
    label: "Status",
    type: "select",
    options: SELECT_OPTIONS.ACCOUNT_STATUSES,
  },
  systemCode: { label: "System Code", type: "text" },
  // role: { label: "Role", type: "select", options: SELECT_OPTIONS.ROLES },
  state: { label: "State", type: "select", options: SELECT_OPTIONS.STATES },
  gender: { label: "Gender", type: "select", options: SELECT_OPTIONS.GENDERS },
};

interface ProfileType {
  data: any;
  showImage?: boolean | undefined;
  imageURL?: string;
  showBanner?: boolean | undefined;
  showHeader?: boolean;
  header?: string;
  secondButtonText?: string;
  fn?: () => void;
  flag?: boolean;
  status?: string;
  showButton?: boolean;
  showSecondButton?: boolean;
  onClick?: () => void;
  secondOnClick?: () => void;
  onClose?: any;
  makeRequest?: Function;
  handleModal?: Function;
  apiResult?: { isLoading: boolean };
  showModal?: boolean;
  suspend?: string;
}

export default function ProfileEditCard(props: ProfileType) {
  const [formData, setFormData] = useState(props.data);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    // Add logic to handle form submission, e.g., making an API request
    if (props.makeRequest) {
      props.makeRequest(formData);
    }
  };

  return (
    <div className="w-full h-fit bg-white shadow-lg rounded-lg text-[14px] py-6">
      {props.showHeader ? (
        <div className="my-3">
          <h1 className="font-bold text-lg text-black text-center ">
            {props.header}
          </h1>
        </div>
      ) : null}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-y-10 py-4 md:gap-x-2 text-start px-4 lg:px-16">
        {!!props.showBanner ? (
          <div>
            <div className=" text-start ">
              <h2 className="text-black">Profile Picture</h2>
              <span className="block bg-[#737587] h-0.5 w-20 my-1.5 rounded-lg"></span>
              <div className="h-20 w-20 mt-6">
                <Image
                  image={props.imageURL || ""}
                  width={"100%"}
                  height={"100%"}
                  styles="object-cover rounded-full"
                />
              </div>
            </div>
          </div>
        ) : null}
        {Object.keys(formData)?.map((dt, i) => {
          if (PROFILE_ATTRIBUTES[dt]?.type === "text") {
            return (
              <div key={i + 1}>
                <FormInput
                  name={PROFILE_ATTRIBUTES[dt]?.label}
                  id={dt}
                  type="text"
                  onChange={handleInputChange}
                  value={formData[dt]}
                  placeholder={`Enter ${dt}`}
                  touched={false}
                  error={""}
                />
              </div>
            );
          } else if (PROFILE_ATTRIBUTES[dt]?.type === "select") {
            return (
              <div key={i + 1}>
                <SelectInput
                  name={PROFILE_ATTRIBUTES[dt]?.label || ""}
                  id={dt}
                  onChange={() => handleInputChange}
                  data={
                    PROFILE_ATTRIBUTES[dt]?.options?.map(
                      (op: any) => op.value
                    ) || []
                  }
                  value={formData[dt]}
                />
              </div>
            );
          } else if (PROFILE_ATTRIBUTES[dt]?.type === "time") {
            return (
              <div key={i + 1}>
                <TimeInput
                  error={false}
                  touched={false}
                  name={PROFILE_ATTRIBUTES[dt]?.label || ""}
                  onChange={() => handleInputChange}
                  value={formData[dt]}
                  defaultValue={formData[dt]}
                />
              </div>
            );
          }
        })}
      </div>
      {props.showButton || props.showSecondButton ? (
        <div className="h-11  mt-4 w-full justify-end flex items-end px-4 lg:px-16 gap-3">
          {props.showButton ? (
            <Button
              text="Save Changes"
              type="button"
              showModal={props?.showModal}
              disabled={props?.showModal}
              showIcon={false}
              onClick={handleSubmit}
            />
          ) : null}
          {props.showSecondButton ? (
            <Button
              text={props?.secondButtonText || ""}
              type="button"
              showIcon={false}
              onClick={props?.secondOnClick}
            />
          ) : null}
        </div>
      ) : null}
      {!!props.showImage ? (
        <div>
          <div className="py-4 text-start px-4 lg:px-16">
            <h2 className="text-black">Profile Picture</h2>
            <span className="block bg-[#737587] h-0.5 w-20 my-1.5 rounded-lg"></span>
            <div className="w-36 h-36 rounded-full mt-6">
              {props?.imageURL ? (
                <Image
                  image={props.imageURL || ""}
                  width={"100%"}
                  height={"100%"}
                  styles="object-cover h-full rounded-full"
                />
              ) : (
                <div className="w-[90px] h-[90px] rounded-full self-center object-fit bg-[#D9D9D9] flex justify-center items-center">
                  <h1 className="text-xl text-center font-extrabold">
                    {props?.data?.firstName?.charAt(0) || ""}
                    {props?.data?.lastName?.charAt(0) || ""}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
