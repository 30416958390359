import React, { Fragment, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useCustomLocation from "src/hooks/useCustomLocation";
import walletBtn from "src/assets/img/walletbtn.svg";
import User from "src/assets/img/User.svg";
import Wallet from "src/assets/img/UserWallet.svg";
import { cardBtnType, FormType, ProfiledataType } from "src/helpers/alias";
import ProfileCard from "src/components/ProfileCard";
import { CardButton } from "src/components/Card";
import { APP_ROUTE, PERMISSION } from "src/helpers/Constant";
import {
  useDeactivateUserMutation,
  useFetchUserQuery,
  useUpdateUserMutation,
} from "src/api/manageUserApi";
import { LoaderContainer } from "src/components/LoaderContainer";
import { format } from "date-fns";
import UserWallet from "./UserWallet";
import { FlagModal, FormModal, Modal } from "src/components/ModalComp";
import { toast } from "react-toastify";
import { Button } from "src/components/Button";
import ProfileEditCard from "src/components/EditProfileCard";
import { useGetUserTransactionsQuery } from "src/api/transactionsApiSlice";
import UserReferalInfo from "./UserReferalInfo";
import Users from "src/assets/img/attendant.svg";
import {
  CurrencyFormatter,
  handleDateFormat,
  handleNotification,
  splitByUpperCase,
  SuccessNotification,
} from "src/helpers/helperFunction";
import { useLockHqWalletMutation } from "src/api/walletAPISlice";
import { Box, Tab, Tabs } from "@mui/material";
import { checkPermissions } from "src/components/PermissionRestrictorComp";
import { useAuth } from "src/hooks/useAuth";

export default function UserProfile() {
  const [showModal, setShowModal] = useState(false);
  const { slicedPath } = useCustomLocation();
  const id = slicedPath[2];
  const userResult = useFetchUserQuery(id);
  const [deactivateUserMutation, deactivateUserResult] =
    useDeactivateUserMutation();

  const [updateUserDetails, updateUserDetailsResult] = useUpdateUserMutation();

  async function updateUserProfile(values: FormType) {
    try {
      const response = await updateUserDetails({
        id: userResult?.currentData?.user.id,
        ...values,
      }).unwrap();
      console.log(response);
      if (response?.status === "success") {
        SuccessNotification("User Updated");
        setShowModal(() => false);
      }
    } catch (error: any) {
      handleNotification(error);
    }
  }

  const handleUserEdit = (data: any) => {
    return (
      {
        firstName: data.firstName,
        lastName: data?.lastName,
        phoneNumber: data?.phoneNumber,
      } || {}
    );
  };

  const handleApiResponse = useMemo(() => {
    const result = userResult?.currentData?.user ?? {};

    return {
      profile: {
        firstName: result.firstName,
        lastName: result.lastName,
        email: result.email,
        gender: result.gender,
        phoneNumber: result?.phoneNumber,
        residentialAddress: result?.residentialAddress,
        status: result?.accountStatus?.status,
        createdAt: result?.createdAt && handleDateFormat(result?.createdAt),
        updatedAt: result?.updatedAt && handleDateFormat(result?.updatedAt),
      },
      id: result.id,
      wallet: {
        walletName: result?.wallet?.walletName,
        status: result?.wallet?.locked,
        walletNumber: result?.wallet?.walletNumber,
        bankName: result?.wallet?.bankName,
      },
      referral: {
        referralCode: result?.referralCode,
        inviteCodeStatus: result?.inviteCodeStatus,
      },
      referralCode: result?.referralCode,
      account: result?.wallets?.accountBalance || 0,
      profilePicture: result?.profilePicture?.url,
    };
  }, [userResult]);

  const [showCard, setShowCard] = useState<string>("View Profile");

  const HQData: cardBtnType[] = [
    {
      id: 1,
      icon: User,
      name: "View Profile",
      state: "profile",
      link: APP_ROUTE.USER_PROFILE,
    },
    {
      id: 2,
      icon: Wallet,
      state: "wallet",
      name: "Wallet Information",
      link: APP_ROUTE.USER_WALLET,
    },
    {
      id: 3,
      icon: Users,
      state: "referral_information",
      name: "Referral Information",
      link: APP_ROUTE.REFFERALS,
    },
  ];
  const auth = useAuth();
  function handleModal() {
    setShowModal((prevState) => !prevState);
  }
  async function makeRequest() {
    try {
      await deactivateUserMutation({
        accountStatus: {
          status:
            handleApiResponse?.profile?.status === "pending" ||
            handleApiResponse?.profile?.status === "confirmed" ||
            handleApiResponse?.profile?.status === "suspended"
              ? "deactivated"
              : handleApiResponse?.profile?.status === "deactivated"
              ? "confirmed"
              : "deactivated",
          reason: "suspended",
        },
        id,
      });

      toast.success("Status updated");
    } catch (error: any) {
      toast.error(error?.message as any) as any;
    }
    setShowModal((prevState) => !prevState);
  }

  return (
    <section>
      <article className="w-full h-screen px-2">
        <LoaderContainer data={userResult}>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4  py-3">
            <>
              {HQData.map((dt) => (
                <Fragment key={dt.id}>
                  <CardButton
                    name={dt.name}
                    icon={dt.icon}
                    height={"98px"}
                    activeBtn={showCard}
                    onClick={() => setShowCard(dt?.name)}
                    // showCard={showCard}
                  />
                </Fragment>
              ))}
            </>
          </div>
          {/* <div className="flex">
            {showCard === "View Profile" ? (
              <div className=" px-10">
                <div className="w-[189px] h-11">
                  <Button
                    text={
                      handleApiResponse?.profile?.status === "pending"
                        ? "Activate"
                        : handleApiResponse?.profile?.status === "confirmed"
                        ? "Suspend"
                        : "Unsuspend"
                    }
                    className="h-full font-bold text-white rounded-[38px] w-full hover: bg-[#002E66] flex items-center justify-center"
                    type="button"
                    onClick={handleModal}
                  />
                </div>
              </div>
            ) : null}
          </div> */}
          {showCard === "Wallet Information" ? (
            <UserAccountBalance
              account={handleApiResponse?.account}
              info={handleApiResponse}
            />
          ) : null}
          {showCard === "Referral Information" ? (
            <>
              <ProfileCard
                // secondOnClick={handleModal}
                // secondButtonText={
                //   handleApiResponse?.profile?.status === "pending"
                //     ? "Activate"
                //     : handleApiResponse?.profile?.status === "confirmed"
                //     ? "Suspend"
                //     : "Unsuspend"
                // }
                // showSecondButton={true}
                showBanner={false}
                data={handleApiResponse?.referral}
              />
              <UserReferalInfo
                id={id}
                referralCode={handleApiResponse.referralCode}
              />
            </>
          ) : null}

          {showCard === "View Profile" ? (
            <ProfileCard
              secondOnClick={handleModal}
              secondButtonText={
                handleApiResponse?.profile?.status === "pending" ||
                handleApiResponse?.profile?.status === "confirmed"
                  ? "Suspend"
                  : handleApiResponse?.profile?.status?.includes("suspend") ||
                    handleApiResponse?.profile?.status === "deactivated"
                  ? "Unsuspend"
                  : ""
              }
              showSecondButton={
                checkPermissions(auth?.user?.role || "", [
                  PERMISSION.SYSTEM_ADMIN,
                ]) && true
              }
              showBanner={true}
              data={handleApiResponse?.profile || {}}
              imageURL={handleApiResponse?.profilePicture || ""}
              onClick={() => setShowCard("Edit Profile")}
              buttonText={"Edit User"}
              showButton={
                checkPermissions(auth?.user?.role || "", [
                  PERMISSION.SYSTEM_ADMIN,
                ]) && true
              }
              flag={true}
              handleModal={handleModal}
              suspend={
                handleApiResponse?.profile?.status === "pending" ||
                handleApiResponse?.profile?.status === "confirmed"
                  ? "Suspend"
                  : handleApiResponse?.profile?.status?.includes("suspend")
                  ? "Unsuspend"
                  : ""
              }
            />
          ) : null}

          {showCard === "Edit Profile" ? (
            <ProfileEditCard
              secondOnClick={() => setShowCard("View Profile")}
              secondButtonText={"Cancel"}
              showSecondButton={true}
              showBanner={false}
              data={handleUserEdit(handleApiResponse?.profile) || {}}
              imageURL={handleApiResponse?.profilePicture || ""}
              showImage={false}
              flag={true}
              makeRequest={updateUserProfile}
              showModal={updateUserDetailsResult.isLoading}
              showButton={true}
              handleModal={handleModal}
              suspend={
                handleApiResponse?.profile?.status === "pending" ||
                handleApiResponse?.profile?.status === "confirmed"
                  ? "Suspend"
                  : handleApiResponse?.profile?.status?.includes("suspend")
                  ? "Unsuspend"
                  : ""
              }
            />
          ) : null}

          {showModal && (
            <Modal>
              <FlagModal
                info={`Are you sure you want to ${
                  handleApiResponse?.profile?.status === "confirmed" ||
                  handleApiResponse?.profile?.status === "pending"
                    ? `Suspend ${handleApiResponse?.profile?.firstName}`
                    : handleApiResponse?.profile?.status === "deactivated"
                    ? `activate ${handleApiResponse?.profile?.firstName}`
                    : `Unsuspend ${handleApiResponse?.profile?.firstName}`
                }?`}
                onClose={handleModal}
                onConfirmation={makeRequest}
                showModal={deactivateUserResult?.isLoading}
              />
            </Modal>
          )}
        </LoaderContainer>
      </article>
    </section>
  );
}

export const UserAccountBalance = (props: {
  account: string;
  info: { [index: string]: string | any };
}) => {
  const [activeTab, setActiveTab] = useState<string>("balance");
  const [walletData, setWalletData] = useState<any>(props.info.walletInfo);
  const [showLockModal, setShowLockModal] = useState<boolean>(false);
  const [isLocking, setIsLocking] = useState<boolean>(true);
  const auth = useAuth();
  const toggleTab = (tab: string) => setActiveTab(tab);

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWalletData({ ...walletData, [e.target.name]: e.target.value });
  };

  const [lockHqWallet, lockHqWalletDetails] = useLockHqWalletMutation();

  const handleLockWallet = async () => {
    try {
      const response = await lockHqWallet({
        id: props?.info?.id,
        locked: true,
        canWithdraw: true,
      }).unwrap();
      SuccessNotification(response.message);
      setShowLockModal(false);
    } catch (error: any) {
      handleNotification(error);
    }
  };

  const handleUnlockWallet = async () => {
    try {
      const response = await lockHqWallet({
        id: props?.info?.id,
        locked: false,
        canWithdraw: false,
      }).unwrap();
      SuccessNotification(response.message);
      setShowLockModal(false);
      setShowLockModal(false);
    } catch (error: any) {
      handleNotification(error);
    }
  };

  const btnClass =
    "h-full font-bold text-white rounded-lg px-6 py-2 bg-[#002E66] hover:bg-[#004b99] transition duration-300";

  const handleChange = (event: any) => {
    const t: string = event.target.textContent;
    t.toLowerCase().includes("balance")
      ? setActiveTab("balance")
      : setActiveTab("lock");
  };
  return (
    <div className="pb-10">
      <div className="p-6 basis-[60%] rounded-[10px] bg-white mt-6 shadow-lg">
        {/* <div className="flex justify-between items-center mb-6">
          <div className="flex space-x-4">
            <Button
              text="Balance"
              className={`${btnClass} ${
                activeTab === "balance" ? "bg-[#004b99]" : ""
              }`}
              type="button"
              showIcon={false}
              onClick={() => toggleTab("balance")}
            />
            <Button
              text="Lock/Unlock"
              className={`${btnClass} ${
                activeTab === "lock" ? "bg-[#004b99]" : ""
              }`}
              type="button"
              showIcon={false}
              onClick={() => toggleTab("lock")}
            />
          </div>
        </div> */}
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={activeTab}
            onClick={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              sx={{
                fontSize: 14,
              }}
              key={"balance"}
              value={"balance"}
              label={"Balance"}
            />
            {checkPermissions(auth?.user?.role || "", [
              PERMISSION.SYSTEM_ADMIN,
            ]) && (
              <Tab
                sx={{
                  fontSize: 14,
                }}
                key={"lock"}
                value={"lock"}
                label={"Lock Wallet"}
              />
            )}
          </Tabs>
        </Box>
        {activeTab === "balance" && (
          <div className="grid gap-6">
            {checkPermissions(auth?.user?.role || "", [
              PERMISSION.SYSTEM_ADMIN,
            ]) && (
              <div className="text-center text-[#002E66]">
                <h3 className="text-[14px]">Available Balance</h3>
                <h2 className="text-[24px] font-bold">
                  {CurrencyFormatter(Number(props.account))}
                </h2>
              </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {Object.keys(props?.info?.wallet)?.map((key, i) => (
                <div
                  key={i + 1}
                  className="bg-gray-100 p-4 pt-1 pb-1 rounded-lg"
                >
                  <h2 className="text-black capitalize">
                    {splitByUpperCase(key)}
                  </h2>
                  <h2 className="text-[#002E66]">
                    {key === "status"
                      ? props?.info?.wallet?.[key]
                        ? "Locked"
                        : "Not Locked"
                      : props?.info?.wallet?.[key]}
                  </h2>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === "lock" && (
          <div className="flex w-full flex-col gap-4 items-center justify-between">
            {!props?.info?.wallet?.status && (
              <div className="flex w-full items-center justify-between">
                <p className="my-4">Wallet is unlocked, lock?</p>
                <Button
                  type="button"
                  text="Lock Wallet"
                  className={btnClass}
                  showIcon={false}
                  onClick={() => {
                    setIsLocking(true);
                    setShowLockModal(true);
                  }}
                />
              </div>
            )}
            {props?.info?.wallet?.status && (
              <div className="flex w-full items-center justify-between">
                <p className="my-4">Wallet is locked, unlock?</p>
                <Button
                  type="button"
                  text="Unlock Wallet"
                  className={btnClass}
                  showIcon={false}
                  onClick={() => {
                    setIsLocking(false);
                    setShowLockModal(true);
                  }}
                />{" "}
              </div>
            )}
          </div>
        )}
      </div>

      <UserWallet id={props?.info?.id} />

      {showLockModal && (
        <FormModal
          name={isLocking ? "Lock Wallet" : "Unlock Wallet"}
          onClick={() => setShowLockModal(false)}
        >
          <div className="p-4">
            {/* <h2 className="text-lg font-bold">
              {isLocking ? "Lock Wallet" : "Unlock Wallet"}
            </h2> */}
            <p className="my-4">
              Are you sure you want to {isLocking ? "lock" : "unlock"} the
              wallet?
            </p>
            <div className="flex justify-end space-x-4">
              <Button
                type="button"
                text="Cancel"
                className="px-4 py-2 bg-gray-500 rounded"
                showIcon={false}
                onClick={() => setShowLockModal(false)}
              />
              <Button
                type="button"
                showModal={lockHqWalletDetails.isLoading}
                text="Confirm"
                className="px-4 py-2 bg-[#002E66] rounded text-white"
                showIcon={false}
                onClick={isLocking ? handleLockWallet : handleUnlockWallet}
              />
            </div>
          </div>
        </FormModal>
      )}
    </div>
  );
};
