import { useFormik } from "formik";
import React, { useMemo } from "react";
import { useUpdateDepotMutation } from "src/api/manageHQApiSlice";
import { Button } from "src/components/Button";
import {
	ErrorNotification,
	SuccessNotification,
	convert2base64,
} from "src/helpers/helperFunction";
import * as yup from "yup";
import { DepotInformation } from "./DepotInformation";
import { useLocation } from "react-router-dom";
import useCustomLocation from "src/hooks/useCustomLocation";

export default function EditDepot() {
	const [updateDepotMutation, updateDepotResult] = useUpdateDepotMutation();
	const location = useLocation();
	const { slicedPath } = useCustomLocation();

	const itemData = useMemo(() => location?.state, [location]);

	const formik = useFormik({
		initialValues: {
			depot: {
				logo: itemData?.logo?.url,
				name: itemData?.name || "",
				email: itemData?.email,
				phoneNumber: itemData?.phoneNumber,
				address: itemData?.address,
				state: itemData?.state,
			},
		},
		validateOnChange: true,
		validateOnBlur: true,
		validationSchema: yup.object().shape({
			depot: yup.object().shape({
				email: yup.string().label("Email").required(),
				phoneNumber: yup.string().label("Phone number").required(),
				name: yup.string().label("Depot name").required(),
				address: yup.string().label("Address").required(),
				logo: yup.string().label("Logo").required(),
			}),
		}),

		onSubmit: async (value: any) => {
			try {
				const response = await updateDepotMutation({
					id: slicedPath[2],
					...value,
				}).unwrap();
				console.log(response);
				SuccessNotification(response?.status);
			} catch (error: any) {
				ErrorNotification(error?.data?.message);
			}
		},
	});

	const uploadSelfImage = async (event: any) => {
		let images: any[] = [];

		const { name, files } = event.target;

		const file = files[0];

		if (images?.length > 0) {
			return;
		}

		return [...images, { [name]: await convert2base64(file) }];
	};

	async function uploadLogo(event: { target: { name: string } }) {
		const name = event.target.name;
		const logoImage = await uploadSelfImage(event);

		formik.setFieldValue("depot.logo", logoImage?.find((image) => image)[name]);
	}

	return (
		<section>
			<article className="w-full h-full py-4 shadow-lg">
				<div className=" h-full  lg:w-3/4  mx-auto">
					<form
						className="w-full px-4 lg:px-0 lg:w-3/4 "
						onSubmit={formik.handleSubmit}>
						<DepotInformation formik={formik} uploadLogo={uploadLogo} />

						<div className="flex justify-between items-center py-4 gap-x-4">
							<Button
								text={"Submit"}
								className="h-full w-full rounded-full font-bold bg-primary text-white   flex items-center justify-center px-10 py-4"
								type="submit"
								showModal={updateDepotResult?.isLoading}
							/>
						</div>
					</form>
				</div>
			</article>
		</section>
	);
}

export const ImagePreview = ({ image }: { image: string }) => {
	return (
		<div className="w-3/4 h-56 my-4 mx-auto">
			<img src={image} className="w-full h-full  object-contain" alt="" />
		</div>
	);
};
