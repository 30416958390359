//FETCH_ALL_USER

import { InvalidateTag, providesTagList } from "src/helpers/helperFunction";
import { API_ROUTE, RTKTAG } from "src/helpers/Constant";
import { selfHelpValidation } from "src/screens/dashboard/pages/self-help/AddNewSelfHelp";
import { apiSlice } from "./apiSlice";

//page=${params?.page}&
export const selfHelpAPISlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		fetchAllSelfHelp: builder.query({
			query: (params) =>
				({
					url: `${API_ROUTE.SELF_HELP}?orderBy=createdAt:desc`,
					params,
				} as any),
			providesTags: [{ type: RTKTAG.SELP_HELP }],
		}),
		fetchSingleHelp: builder.query({
			query: (params) =>
				({
					url: `${API_ROUTE.SELF_HELP}/${params}?orderBy=createdAt:desc`,
				} as any),
			providesTags: [{ type: RTKTAG.SELP_HELP }],
		}),
		addNewSelfHelp: builder.mutation({
			query: (body: selfHelpValidation): any => ({
				url: API_ROUTE.SELF_HELP,
				method: "POST",
				body,
			}),
			invalidatesTags: [{ type: RTKTAG.SELP_HELP }],
		}),
		editNewSelfHelp: builder.mutation({
			query: ({ id, ...body }: any): any => ({
				url: `${API_ROUTE.SELF_HELP}/${id}`,
				method: "PATCH",
				body,
			}),
			invalidatesTags: [{ type: RTKTAG.SELP_HELP }],
		}),
		deleteSelfHelp: builder.mutation({
			query: (id): any => ({
				url: `${API_ROUTE.SELF_HELP}/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: [{ type: RTKTAG.SELP_HELP }],
		}),
	}),
});

export const {
	useFetchAllSelfHelpQuery,
	useAddNewSelfHelpMutation,
	useDeleteSelfHelpMutation,
	useEditNewSelfHelpMutation,
	useFetchSingleHelpQuery,
} = selfHelpAPISlice;
