export enum API_ROUTE {
  LOGIN = "auth/system-admin/login-email",
  STATES = "system-admin/states",
  FETCH_ALL_USER_REFERRALS = "/system-admin/referrals",
  GET_ALL_HQ = "/system-admin/station-hq",
  SUSPEND_HQ = "/system-admin/station/users",
  ADD_NEW_HQ = "system-admin/station-hq",
  GET_ALL_BRANCH = "/system-admin/station/branches",
  ADD_NEW_BRANCH = "station/branch",
  FETCH_ALL_TRANSACTION = "system-admin/transactions",
  FETCH_HQ_WALLET = "station/wallet",

  LOCK_HQ_WALLET = "system-admin/station/hq/wallet",
  LOCK_BRANCH_WALLET = "system-admin/station/branch/wallet",
  LOCK_USER_WALLET = "system-admin/user/wallet",

  FETCH_ALL_USERS = "system-admin/users",
  FETCH_ALL_USER = "system-admin/user",
  UPDATE_USER = "system-admin/user",
  UPDATE_STATION_USER = "system-admin/station/users",
  UPDATE_BRANCH = "system-admin/station/branch",
  FETCH_BRANCH = "system-admin/station/branch",
  UPDATE_BRANCH_REVIEW_STATUS = "system-admin/station/branch/ratings",
  FETCH_SINGLE_HQ = "system-admin/station-hq",
  SELF_HELP = "system-admin/self-help",
  ADMIN = "system-admin",
  SAVE_WEBSITE_INFO = "system-admin/website-info",
  NOTIFICATION = "system-admin/notification",
  TICKET = "system-admin/ticket",
  FEEDS = "system-admin/feed",
  FORGOT_PASSWORD = "auth/system-admin/forgot-password",
  RESET_PASSWORD = "auth/system-admin/reset-password",
  RESEND_OTP = "auth/service-provider/resend-otp",
  DASHBOARD_INFO = "system-admin/dashboard-summary",
  DEPOT = "system-admin/depot",

  ADD_HQ_BRANCH = "system-admin/station-branch",
  HQ_ADMIN_LOGIN = "auth/station/login-email",
  // station/branch/646def63111438d2777c5d06
  STATION_BRANCH_CHANGE = "station/branch",
}

export enum APP_ROUTE {
  All = "*",
  DASHBOARD = "/",
  ABOUT_US = "/about",
  CONTACT_US = "/contact",
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot-password",
  BRANCH = "manage-Branch",
  TRANSACTIONS = "transactions",
  SUPPORT = "support",
  NOTIFICATION = "notification",
  SELF_HELP = "self-help",
  SETTINGS = "settings",
  DEPOT = "/Depot",
  SINGLE_DEPOT = "/Depot/:id",
  DEPOT_CREATE = "/Depot/create",
  DEPOT_EDIT = "/Depot/:id/edit",
  USER = "/users",
  USER_PROFILE = "/users/:name",
  USER_PROFILE_EDIT = "/users-edit/:name",
  USER_WALLET = "/users/:name/wallet",
  REFFERALS = "/users/:name/referrals",
  MANAGEHQ = "/manage-hq",
  MANAGE_SINGLE_HQ = "manage-hq/:id",
  MANAGE_SINGLE_BRANCH = "manage-branches/:id",
  BRANCHES = "/branches",
  VIEW_WALLET = "/view/wallet",
  ATTENDANT_PROFILE = "branch/:id/attendant",
  ATTENDANT_PROFILE_INFO = "branch/:id/attendant/:name",
  ATTENDANT_REVIEW = "branch/:id/reviews",
  VIEW_HQ_WALLET = "view/:id/wallet",
  VIEW_HQ_BRANCH = "/manage-branches",
  ADMIN_PROFILE = "admin/profile",
  ADMIN_PROFILE_EDIT = "admin/profile/edit",
  MANAGE_ADMIN = "manage/admin",
  MANAGE_WEBSITE = "manage/website",
  RESET_PASSWORD = "/reset-password",
  MANAGE_REFFERAL = "/refferal-settings",
  ADD_NEW_SELF_HELP = "/add/new/self-help",
  EDIT_NEW_SELF_HELP = "/edit/self-help",
  ADD_NEW_SELF_HELP_SINGLE = "/self-help/:name",
  FEEDS = "/feeds",
  SINGLE_ADMIN = "/settings/manage-admin/:id",
}

export enum HQ_APP_ROUTE {
  BRANCH = "/manage-branch",
  SINGLE_BRANCH = "/manage-branch/:id",
  TRANSACTIONS = "/transactions",
  SUPPORT = "/support",
  NOTIFICATION = "/notification",
  SETTINGS = "/settings",
}

export enum HQ_API_ENPOINTS {
  BRANCH = "station/branches",
  SINGLE_BRANCH = "station/branch",

  TRANSACTIONS = "station/transactions",
  // SUPPORT = "/support",
  NOTIFICATION = "station/notification",
  SETTINGS = "station/update-user",
  FORGOT_PASSWORD = "auth/station/forgot-password",
  RESET_PASSWORD = "auth/station/reset-password",
  RESEND_OTP = "auth/station/resend-otp",
}

export enum PERMISSION {
  ENGINEER = "engineer",
  SYSTEM_ADMIN = "super_admin",
  FEEDS = "feeds",
  TRANSACTION = "transaction",
  SUBADMIN = "sub_admin",
}

export enum SUB_DOMAIN {
  HQ = "hq",
  SYSTEM_ADMIN = "localhost:3000",
}

export enum RTKTAG {
  STATES = "STATES",
  MANAGE_HQ = "MANAGE_HQ",
  MANAGER_BRANCH = "MANAGE_BRANCH",
  WALLET = "WALLET",
  ADMIN = "ADMIN",
  WEBSITE_INFO = "WEBSITE",
  NOTIFICATION = "NOTIFICATION",
  SELP_HELP = "SELF_HELP",
  FEEDS = "FEEDS",
  HQ_BRANCH = "BRANCH",
  USERS = "USERS",
  DEPOT = "DEPOT",
}

export enum KEYS {
  USER_INFO = "fuleap-user-info",
}
export enum TRANSACTION_TYPES {
  CREDIT = "credit",
  DEBIT = "debit",
  LOAN = "loan",
  REFUND = "refund",
}

export const CATEGORIES = [
  "withdrawal",
  "in_app_transfer",
  "fuel_purchase",
  "invoice_payment",
  "referral_bonus",
  "bank_transfer",
  "reversal",
  "deposit",
  "refund",
  "dispute",
  "premium_feature",
];

export const SOURCES = [
  "available_balance",
  "bank_transfer",
  "instant_wallet",
  "cash",
  "card_deposit",
  "user_transfer",
  "hq_transfer",
];

export const TYPES = ["credit", "debit", "loan", "refund"];

export const STATUS = ["pending", "successful", "failed"];

const GENDERS = [
  { id: "male", label: "Male", value: "male" },
  { id: "female", label: "Female", value: "female" },
];

const ROLES = [
  { id: "stationBranch", label: "Station Branch", value: "stationBranch" },
  { id: "stationHq", label: "Station HQ", value: "stationHq" },
  { id: "user", label: "User", value: "user" },
];

const STATES = [
  { id: "abuja", label: "Abuja", value: "abuja" },
  { id: "lagos", label: "Lagos", value: "lagos" },
];

const ACCOUNT_STATUSES = [
  { label: "Deactivated", value: "deactivated" },
  { label: "Confirmed", value: "confirmed" },
];

export const SELECT_OPTIONS = {
  GENDERS,
  ROLES,
  STATES,
  ACCOUNT_STATUSES,
};

// export enum TRANSACTION_SOURCES {
// 	BANK_TRANSFER = "bank_transfer",
// 	CARD_DEPOSIT = "card_deposit",
// 	AVAILABLE_BALANCE = "available_balance",
// 	LEDGER_BALANCE = "ledger_balance",
// 	RESERVED_BALANCE = "reserved_balance",
// 	STASH = "stash",
// 	REVERSAL = "reversal",
// 	USER_TRANSFER = "user_transfer",
// 	INSTANT_WALLET = "instant_wallet",
// 	HQ_TRANSFER = "hq_transfer",
// }
