import { CalendarMonthOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { Label } from "./inputs";
// import ReactDatePicker from "react-datepicker";

export const DateRangeComp = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}: any) => (
  <Box className="flex w-[286px] items-center gap-2 rounded-lg  border-2 px-4 py-2 text-base text-[#344054] drop-shadow-[0px_1px_1px_rgba(16,24,40,0.05)]">
    <CalendarMonthOutlined />
    {/* <ReactDatePicker
			className="w-full bg-transparent outline-none"
			dateFormat="MMM, d yyyy"
			selected={startDate}
			onChange={onStartDateChange}
			selectsStart
			startDate={startDate}
			endDate={endDate}
		/> */}
    <div className="h-0.5 w-8 bg-[#344054]"></div>
    {/* <ReactDatePicker
			className="w-full bg-transparent outline-none"
			dateFormat="MMM, d yyyy"
			selected={endDate}
			onChange={onEndDateChange}
			selectsEnd
			startDate={startDate}
			endDate={endDate}
			minDate={startDate}
		/> */}
  </Box>
);

export const DateRange = ({
  name,
  onChange,
  labelStyles,
}: {
  name: string;
  labelStyles: string;
  value: string;
  onChange: (arg: { target: { value: string } }) => void;
}) => {
  return (
    <>
      <Label name={name} styles={labelStyles} />
      <input
        className="py-2 border border-gray-200 rounded-lg px-2"
        type="date"
        name={name}
        onChange={onChange}
      />
    </>
  );
};
export const TimeInput = ({
  name,
  onChange,
  value,
  defaultValue,
  labelStyles = "block mb-[6px] text-black text-start font-normal text-[20px] text-gray-700 ",
  error,
  touched,
}: {
  name: string;
  value: string;
  defaultValue: string;
  error: boolean;
  touched: boolean;
  labelStyles?: string;
  onChange: (arg: { target: { value: string } }) => void;
}) => {
  const styles = `h-[40px] rounded-lg w-full border border-gray-300 px-4 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 ${
    error && touched ? "border-red-500" : "border-gray-300"
  } bg-[#D9D9D9]`;

  function convertToValidTimeInput(time: string) {
    // Remove any whitespace
    time = time.trim();

    // Regex to match 12-hour format with optional AM/PM
    const regex = /^(\d{1,2}):(\d{2})(AM|PM)?$/i;
    const match: any = time.match(regex);

    if (!match) {
      throw new Error("Invalid time format");
    }

    let [, hours, minutes, period] = match;

    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    // Convert to 24-hour format if period is specified
    if (period) {
      period = period.toUpperCase();
      if (period === "PM" && hours < 12) {
        hours += 12;
      } else if (period === "AM" && hours === 12) {
        hours = 0;
      }
    }

    // Ensure hours and minutes are two digits
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  }

  return (
    <>
      <Label name={name} styles={labelStyles} />
      <input
        className={styles}
        type="time"
        value={`${convertToValidTimeInput(value)}`}
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
      />
    </>
  );
};
