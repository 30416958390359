import { useMemo } from "react";
import ViewWalletComp from "src/components/ViewWalletComponent";
import { CurrencyFormatter, truncateString } from "src/helpers/helperFunction";
import { TransactionsType } from "src/helpers/alias";
import { useGetUserTransactionsQuery } from "src/api/transactionsApiSlice";
import { TableLoader } from "src/components/LoaderContainer";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { CATEGORIES, SOURCES, STATUS, TYPES } from "src/helpers/Constant";
import { Badge } from "src/components/Badge";
import { enUS } from "date-fns/locale";

/**
https://pyro-staging-api.up.railway.app/api/v1/system-admin/transactions?limit=6&page=1&source=user_transfer&orderBy=createdAt:desc&who=stations&for=station_branch&populate=stationBranch&search=&startDate=2023-09-24&endDate=2023-09-30
 */

// TRANSACTION TABLE FILTER INFORMATION

export const headCells: any[] = [
  {
    id: "created",
    minWidth: 220,
    label: "Transaction Date",
  },
  {
    id: "accountName",
    minWidth: 190,
    label: "Name",
  },
  {
    id: "amount",
    minWidth: 120,
    label: "Amount (N)",
  },
  {
    id: "type",
    minWidth: 90,
    label: "Type",
  },
  {
    id: "reference",
    minWidth: 170,
    label: "Reference",
  },
  {
    id: "category",
    minWidth: 170,
    label: "Category",
  },
  {
    id: "source",
    minWidth: 190,
    label: "Source",
  },

  // {
  //   id: "systemCode",
  //   minWidth: 170,
  //   label: "System Code",
  // },

  {
    id: "status",
    minWidth: 100,
    label: "Status",
  },

  // {
  //   id: "accountName",
  //   minWidth: 170,
  //   label: "Recipient Account name",
  // },
  // {
  //   id: "message",
  //   minWidth: 170,
  //   label: "Message",
  // },
  // {
  //   id: "transactionType",
  //   minWidth: 170,
  //   label: "Transacton Type",
  // },
];

export default function UserTransactions({
  handleRowClick,
  handleChangePage,
  startDate,
  info,
  endDate,
  debouncedValue,
}: any) {
  const allTransactionsResult = useGetUserTransactionsQuery({
    ...{
      who: "users",
      ...(STATUS.includes(info?.source) && { status: info.source }),
      ...(SOURCES.includes(info?.source) && { source: info.source }),
      ...(CATEGORIES.includes(info?.source) && { category: info.source }),
      ...(TYPES.includes(info?.source) && { type: info.source }),
      ...(info?.page !== 0 && {
        page: info?.page,
      }),
      ...(debouncedValue !== "" && {
        search: debouncedValue,
        reference: debouncedValue,
      }),
      ...(startDate &&
        endDate && {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        }),
    },
  });

  const handledAPIResponse = useMemo(() => {
    const transactions = allTransactionsResult?.currentData?.transactions || [];

    return transactions?.data?.reduce(
      (
        acc: { tableData: { previewData: any } }[],
        cur: TransactionsType | any
      ) => {
        const tableData = {
          created: format(new Date(cur.createdAt), "do MMM yyyy, hh:mm a", {
            locale: enUS,
          }),
          reference: cur?.reference || "----------",
          accountName: `${
            cur.source === "instant_wallet"
              ? cur?.meta?.destinationHolderName || "----------"
              : cur?.type === "credit"
              ? cur?.meta?.recieverName || "----------"
              : cur?.type === "debit"
              ? cur?.meta?.senderName || "----------"
              : ""
          }`,
          amount: CurrencyFormatter(Number(cur?.amount)),
          category: cur?.category ? (
            <Badge
              type={"TRANSACTION_CATEGORY_OPTIONS"}
              status={cur?.category}
            />
          ) : (
            "----------"
          ),
          categoryType: cur?.category || cur?.source,
          source: cur?.source ? (
            <Badge type={"TRANSACTION_SOURCES_OPTIONS"} status={cur?.source} />
          ) : (
            "----------"
          ),
          type: cur?.type ? (
            <Badge type={"TRANSACTION_TYPE_OPTIONS"} status={cur?.type} />
          ) : (
            cur?.type || "----------"
          ),
          status: cur?.status ? (
            <Badge type={"TRANSACTION_STATUS_OPTIONS"} status={cur?.status} />
          ) : (
            cur?.status || "----------"
          ),
          previewData: {},
        };

        tableData.previewData = {
          created: tableData.created,
          reference: tableData.reference,
          accountName: tableData.accountName,
          amount: tableData.amount,
          category: tableData.category,
          categoryType: tableData.categoryType,
          source: tableData.source,
          type: tableData.type,
          status: tableData.status,
          message: cur?.meta?.message || "----------",
          purpose: truncateString(cur?.purpose, 50) || "----------",
          balanceAfterTransaction: cur?.balanceAfterTransaction
            ? CurrencyFormatter(cur?.balanceAfterTransaction)
            : "----------",
        };

        switch (cur.category || cur.source) {
          case "bank_transfer":
            tableData.previewData = {
              ...tableData.previewData,
              sendersName: cur?.meta?.senderName || "----------",
              sendersAccountNumber:
                cur?.meta?.senderAccountNumber || "----------",
              sendersBank: cur?.meta?.bankName || "----------",
              receiversName: cur?.meta?.recieverName || "----------",
              receiversSystemCode:
                cur?.meta?.receiverSystemCode || "----------",
              recieverWalletNumber:
                cur?.meta?.recieverWalletNumber || "----------",
              receiverWalletBank: cur?.meta?.receiverWalletBank || "----------",
            };
            break;
          case "instant_wallet":
            tableData.previewData = {
              ...tableData.previewData,
              sendersName: cur?.meta?.senderName || "----------",
              sendersSystemCode: cur?.meta?.senderSystemCode || "----------",
              receiversName: cur?.meta?.destinationHolderName || "----------",
              category: tableData.category,
              receiversSystemCode:
                cur?.meta?.receiverSystemCode || "----------",
              product: cur?.meta?.product || "----------",
              instantWalletCode:
                cur?.meta?.destinationWalletNumber || "----------",
              recieverWalletNumber:
                cur?.meta?.destinationWalletNumber || "----------",
            };
            break;
          case "withdrawal":
            tableData.previewData = {
              ...tableData.previewData,
              sendersName: cur?.meta?.senderName || "----------",
              sendersSystemCode: cur?.meta?.senderSystemCode || "----------",
              receiversName: cur?.meta?.receiverName || "----------",
              receiversAccountNumber:
                cur?.meta?.receiverAccountNumber || "----------",
              receiversBank: cur?.meta?.receiverBank || "----------",
            };
            break;
          case "referral_bonus":
            tableData.previewData = {
              ...tableData.previewData,
              accountName: cur?.meta?.destinationHolderName || "----------",
              accountSystemCode: cur?.meta?.accountSystemCode || "----------",
              nameOfPersonReferred:
                cur?.meta?.nameOfPersonReferred || "----------",
              systemCodeOfPersonReferred:
                cur?.meta?.systemCodeOfPersonReferred || "----------",
            };
            break;
          case "premium_feature":
            tableData.previewData = {
              ...tableData.previewData,
              branchName: cur?.meta?.branchName || "----------",
              branchSystemCode: cur?.meta?.branchSystemCode || "----------",
              nameOfFeatureAdded: cur?.meta?.nameOfFeatureAdded || "----------",
            };
            break;
          default:
            break;
        }

        return [...acc, { ...tableData }];
      },
      []
    );
  }, [allTransactionsResult]);

  const props = {
    rows: handledAPIResponse || [],
    headCells,
    handleRowClick,
    accountInformation: {
      balance: 0,
      amountIn: 0,
      amountOut: 0,
    },
    handleChangePage,
    paginationData: {
      totalPage: allTransactionsResult?.currentData?.transactions?.totalPages,
      limit: allTransactionsResult?.currentData?.transactions?.limit,
      page: allTransactionsResult?.currentData?.transactions?.page,
    },
  };

  return (
    <section>
      <TableLoader
        data={allTransactionsResult}
        tableData={handledAPIResponse || []}
      >
        <ViewWalletComp {...props} />
      </TableLoader>
    </section>
  );
}
