import { useMemo } from "react";
import { ReactElement } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Branch,
  Help,
  Home,
  HQIcon,
  Indicator,
  Notification,
  Settings,
  Transactions,
  Users,
  Depot,
} from "src/components/Icons";
import { APP_ROUTE, HQ_APP_ROUTE, PERMISSION } from "src/helpers/Constant";
import { useAuth } from "src/hooks/useAuth";

type linkTypes = {
  name: string;
  link: string;
  Icon: ReactElement;
  priviledges?: string[];
  route: string;
};

const linksData = [
  {
    id: 1,
    name: "Dashboard",
    priviledges: [PERMISSION.SYSTEM_ADMIN, PERMISSION.ENGINEER],
    route: "Dashboard",
    Icon: <Home />,
    link: APP_ROUTE.DASHBOARD,
  },
  {
    id: 2,
    name: "Manage HQ",
    route: "Manage HQ",
    Icon: <HQIcon />,
    priviledges: [
      PERMISSION.SYSTEM_ADMIN,
      PERMISSION.ENGINEER,
      PERMISSION.SUBADMIN,
    ],
    link: APP_ROUTE.MANAGEHQ,
  },
  {
    id: 3,
    name: "Manage branch",
    route: "Manage branches",
    Icon: <Branch />,
    priviledges: [
      PERMISSION.SYSTEM_ADMIN,
      PERMISSION.ENGINEER,
      PERMISSION.SUBADMIN,
    ],
    link: APP_ROUTE.VIEW_HQ_BRANCH,
  },

  {
    id: 4,
    name: "Transactions",
    route: "Transactions",
    priviledges: [
      PERMISSION.SYSTEM_ADMIN,
      PERMISSION.ENGINEER,
      PERMISSION.TRANSACTION,
      PERMISSION.SUBADMIN,
    ],
    Icon: <Transactions />,
    link: APP_ROUTE.TRANSACTIONS,
    hq_link: HQ_APP_ROUTE.TRANSACTIONS,
  },
  {
    id: 5,
    name: "Users",
    route: "Users",
    priviledges: [
      PERMISSION.SYSTEM_ADMIN,
      PERMISSION.ENGINEER,
      PERMISSION.SUBADMIN,
    ],
    Icon: <Users />,
    link: APP_ROUTE.USER,
  },
  // {
  //   id: 6,
  //   name: "Depot",
  //   route: "Depot",
  //   priviledges: [PERMISSION.SYSTEM_ADMIN, PERMISSION.ENGINEER],
  //   Icon: <Depot />,
  //   link: APP_ROUTE.DEPOT,
  // },
  // {
  // 	id: 4,
  // 	name: "Support",
  // 	route: "Support",
  // 	priviledges: [PERMISSION.SYSTEM_ADMIN,PERMISSION.ENGINEER],
  // 	Icon: <Support />,
  // 	link: APP_ROUTE.SUPPORT,
  // },
  {
    id: 7,
    name: "Notification",
    route: "Notification",
    priviledges: [
      PERMISSION.SYSTEM_ADMIN,
      PERMISSION.ENGINEER,
      PERMISSION.FEEDS,
      PERMISSION.SUBADMIN,
    ],
    Icon: <Notification />,
    link: APP_ROUTE.NOTIFICATION,
    hq_link: HQ_APP_ROUTE.NOTIFICATION,
  },
  {
    id: 8,
    name: "Quick Fix",
    route: "self Help",
    priviledges: [
      PERMISSION.SYSTEM_ADMIN,
      PERMISSION.ENGINEER,
      PERMISSION.FEEDS,
      PERMISSION.SUBADMIN,
    ],
    Icon: <Help />,
    link: APP_ROUTE.SELF_HELP,
  },
  {
    id: 9,
    name: "Feeds",
    route: "Feeds",
    Icon: <Help />,
    priviledges: [
      PERMISSION.SYSTEM_ADMIN,
      PERMISSION.ENGINEER,
      PERMISSION.FEEDS,
      PERMISSION.SUBADMIN,
    ],
    link: APP_ROUTE.FEEDS,
  },
  {
    id: 10,
    name: "Settings",
    route: "Settings",
    priviledges: [
      PERMISSION.SYSTEM_ADMIN,
      PERMISSION.ENGINEER,
      PERMISSION.FEEDS,
      PERMISSION.SUBADMIN,
    ],
    Icon: <Settings />,
    link: APP_ROUTE.SETTINGS,
    hq_link: HQ_APP_ROUTE.SETTINGS,
  },
];
const DashboardLink = ({ name, link, Icon, route }: linkTypes) => {
  let path = useLocation();

  const firstRoutePath = path.pathname.split("/");
  let firstRoute = firstRoutePath[0];
  let nextRoute = firstRoutePath[1];

  const active = useMemo(() => {
    const selectedLink = route.split(" ").join("-").toLowerCase();

    if (!firstRoute) return nextRoute.toLowerCase() === selectedLink;
    else return firstRoute.toLowerCase() === selectedLink;
  }, [firstRoute, route, nextRoute]);

  const activeLink = active ? "font-[600]" : "font-normal";
  return (
    <div className=" flex items-center ">
      {active ? <Indicator /> : false}
      <Link
        to={link}
        state={name}
        className={`${activeLink} text-white text-[14px] flex w-full px-4 ml-4 h-16 items-center `}
      >
        {Icon}
        <p className="ml-4">{name}</p>
      </Link>
    </div>
  );
};

const SideBar = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handlePriviledge = () => {
    return linksData?.map((dt, i) =>
      dt.priviledges.filter((_v) => _v === user?.role).length > 0 ? (
        <DashboardLink
          name={dt?.name}
          link={user?.role !== "hq_admin" ? dt?.link : dt?.hq_link || ""}
          Icon={dt.Icon}
          route={dt.route}
        />
      ) : null
    );
  };

  return (
    <aside className="h-screen flex flex-col justify-center basis-[18%]  max-w-[260px]">
      <div className=" w-[217px] h-full my-4 rounded-[20px] bg-[#002E66] ml-2 overflow-y-scroll remove-srollbar">
        <div className="w-full pt-6 text-start px-7 text-white"></div>
        {handlePriviledge()}
      </div>
    </aside>
  );
};

export default SideBar;
