import { BarChartComp } from "src/components/Chart";
import { datas } from "src/helpers/data";

import attendant from "src/assets/img/attendant.svg";
import admin from "src/assets/img/AdminProfile.svg";
import branches from "src/assets/img/branches.svg";
import users from "src/assets/img/Users.svg";
import user from "src/assets/img/User.svg";

import { Fragment, useMemo, useState } from "react";
import { DashboardCards, DashboardCardsButton } from "src/components/Card";
import { useDashboardInfoQuery } from "src/api/authApiSlice";
import { LoaderContainer } from "src/components/LoaderContainer";
import { FormType, cardType } from "src/helpers/alias";
import { Button } from "src/components/Button";
import { FormModal } from "src/components/ModalComp";
import AddNewHQ from "src/components/AddNewHQ";
import { useAddHqNewBranchMutation } from "src/hq-admin/hq-api/manageHqApiSlice";
import {
  SuccessNotification,
  handleNotification,
} from "src/helpers/helperFunction";
import { AddNewBranch } from "src/hq-admin/hq-pages/Manage-branch/Components";
import {
  useExportAllHQQuery,
  useFetchAllHQQuery,
} from "src/api/manageHQApiSlice";
import { useDebounce } from "src/hooks/useDebounce";
import { APP_ROUTE } from "src/helpers/Constant";
import { useNavigate } from "react-router-dom";
import Image from "src/components/Image";

const TransactionDashboard = () => {
  const response = useDashboardInfoQuery("");
  const [showModal, setShowAddModal] = useState(false);
  const [AddHQBranch, addHqBranchResult] = useAddHqNewBranchMutation();
  const [showAddModal, setShowNewModal] = useState(false);
  // const allHQQuery = useExportAllHQQuery({});
  const [filteredValue, setFilteredValue] = useState<string>("");
  const navigate = useNavigate();

  const { debouncedValue } = useDebounce(filteredValue, 700);

  const hqQueryResult = useFetchAllHQQuery({
    query: debouncedValue,
  });

  // console.log("thomas testing", hqQueryResult);

  function handleToggleModal() {
    setShowAddModal((prevState) => !prevState);
  }
  const cardData: cardType[] = [
    // {
    // 	id: 1,
    // 	icon: attendant,
    // 	amount: response?.data?.data.stationHqs,
    // 	name: "Station HQs",
    // },
    // {
    // 	id: 2,
    // 	icon: attendant,
    // 	amount: response?.data?.data.stationBranches,
    // 	name: "Station Branches",
    // }, name="HQ stations"
    // {
    //   id: 1,
    //   icon: attendant,
    //   amount: response?.data?.data?.feeds?.totalCount || 0,
    //   name: "Feeds",
    // },
    // {
    //   id: 2,
    //   icon: user,
    //   amount: response?.data?.data?.selfHelp?.totalCount || 0,
    //   name: "Self Help",
    // },
    // {
    //   id: 4,
    //   icon: admin,
    //   amount: response?.data?.data?.stationManager?.totalCount || 0,
    //   name: "Station Admin",
    // },
    // {
    // 	id: 5,
    // 	icon: totalTransactions,
    // 	amount: 20,
    // 	name: "Self Help",
    // },
    // {
    // 	id: 6,
    // 	icon: totalTransactions,
    // 	amount: CurrencyFormatter(Number("12000")),
    // 	name: "Feeds",
    // },
  ];

  const initialValues = {
    stationHQ: "",
    name: "",
    phoneNumber: "",
    location: {
      lga: "",
      state: "",
      latitude: "",
      longitude: "",
      address: "",
    },
    branchManager: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
    },
  };

  async function addNewBranchFunct(values: FormType) {
    try {
      const response = await AddHQBranch(values).unwrap();
      if (response) {
        SuccessNotification(response ? "Add successfully" : "");
        setShowNewModal(() => false);
      }
    } catch (error: any) {
      setShowNewModal(() => false);
      handleNotification(error);
    }
  }

  function handleToggle() {
    setShowNewModal((prevState) => !prevState);
  }

  return (
    <LoaderContainer data={response}>
      <section>
        {/* <LoaderContainer /> */}
        <article className="w-full  flex flex-col gap-3 justify-between overflow-y-auto ">
          <div className=" w-full grid md:grid-cols-3  lg:grid-cols-4 gap-4 ">
            {cardData?.map((dt: any) => {
              return (
                <Fragment>
                  <DashboardCards
                    name={dt.name}
                    icon={dt.icon}
                    amount={dt?.amount?.totalCount || dt?.amount}
                  />
                </Fragment>
              );
            })}

            <div className="h-[134px] rounded-lg bg-white py-4 px-6 flex flex-col justify-between  shadow-lg">
              <div className="flex items-center ">
                <Image image={attendant} width={32} height={32} styles="mr-4" />
                <p className="text-[14px] font-[600] leading-[21px] text-black">
                  Transactions
                </p>
              </div>
              <div className=" flex justify-between items-center ">
                <div className="">
                  <h4 className="font-[600]  text-black text-start text-[12px] ">
                    Users
                  </h4>
                  <h1 className="text-start text-[#737587] text-xl font-[600]">
                    {response?.data?.data?.users?.transactions?.totalCount || 0}
                  </h1>
                </div>
                <div>
                  <h4 className="font-[600]  text-black text-start text-[12px] ">
                    Station
                  </h4>
                  <h1 className="text-start text-[#737587] text-xl font-[600]">
                    {response?.data?.data?.stationTransactions?.totalCount || 0}
                  </h1>
                </div>
                <div>
                  <h4 className="font-[600]  text-black text-start text-[12px] ">
                    Offline
                  </h4>
                  <h1 className="text-start text-[#737587] text-xl font-[600]">
                    {response?.data?.data?.stationOfflineTransactions
                      ?.totalCount || 0}
                  </h1>
                </div>
              </div>
            </div>

            <div className="h-[134px] rounded-lg bg-white py-4 px-6 flex flex-col justify-between  shadow-lg">
              <div className="flex items-center ">
                <Image image={attendant} width={32} height={32} styles="mr-4" />
                <p className="text-[14px] font-[600] leading-[21px] text-black">
                  Users Transactions Summary
                </p>
              </div>
              <div className=" flex justify-between items-center ">
                <div className="">
                  <h4 className="font-[600]  text-black text-start text-[12px] ">
                    Credit
                  </h4>
                  <h1 className="text-start text-[#737587] text-xl font-[600]">
                    {response?.data?.data?.users?.transactions?.type?.credit
                      ?.totalCount || 0}
                  </h1>
                </div>
                <div>
                  <h4 className="font-[600]  text-black text-start text-[12px] ">
                    Debit
                  </h4>
                  <h1 className="text-start text-[#737587] text-xl font-[600]">
                    {response?.data?.data?.users?.transactions?.type?.debit
                      ?.totalCount || 0}
                  </h1>
                </div>
                <div>
                  <h4 className="font-[600]  text-black text-left text-[12px] ">
                    Failed
                  </h4>
                  <h1 className="text-start text-[#737587] text-xl font-[600]">
                    {response?.data?.data?.users?.transactions?.failed
                      ?.totalCount || 0}
                  </h1>
                </div>
                <div>
                  <h4 className="font-[600]  text-black text-left text-[12px] ">
                    Successful
                  </h4>
                  <h1 className="text-start text-[#737587] text-xl font-[600]">
                    {response?.data?.data?.users?.transactions?.successful
                      ?.totalCount || 0}
                  </h1>
                </div>
              </div>
            </div>

            <div className="h-[134px] rounded-lg bg-white py-4 px-6 flex flex-col justify-between  shadow-lg">
              <div className="flex items-center ">
                <Image image={attendant} width={32} height={32} styles="mr-4" />
                <p className="text-[14px] font-[600] leading-[21px] text-black">
                  Station Transactions Summary
                </p>
              </div>
              <div className=" flex justify-between items-center ">
                <div className="">
                  <h4 className="font-[600]  text-black text-start text-[12px] ">
                    Credit
                  </h4>
                  <h1 className="text-start text-[#737587] text-xl font-[600]">
                    {response?.data?.data?.stationTransactions?.type?.credit
                      ?.totalCount || 0}
                  </h1>
                </div>
                <div className="">
                  <h4 className="font-[600]  text-black text-start text-[12px] ">
                    Debit
                  </h4>
                  <h1 className="text-start text-[#737587] text-xl font-[600]">
                    {response?.data?.data?.stationTransactions?.type?.debit
                      ?.totalCount || 0}
                  </h1>
                </div>
                <div>
                  <h4 className="font-[600]  text-black text-start text-[12px] ">
                    Failed
                  </h4>
                  <h1 className="text-start text-[#737587] text-xl font-[600]">
                    {response?.data?.data?.stationTransactions?.failed
                      ?.totalCount || 0}
                  </h1>
                </div>
                <div>
                  <h4 className="font-[600]  text-black text-left text-[12px] ">
                    Successful
                  </h4>
                  <h1 className="text-start text-[#737587] text-xl font-[600]">
                    {response?.data?.data?.stationTransactions?.successful
                      ?.totalCount || 0}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-auto mb-32">
            <div className=" w-full ">
              <BarChartComp data={datas} />
            </div>
          </div>
        </article>
        {showModal ? (
          <FormModal name="Create HQ" onClick={handleToggleModal}>
            <AddNewHQ close={handleToggleModal} />{" "}
          </FormModal>
        ) : null}

        {showAddModal ? (
          <FormModal name="Create Branch" onClick={handleToggle}>
            <AddNewBranch
              initalValue={initialValues}
              apiResult={addHqBranchResult}
              makeApiRequest={(data) => addNewBranchFunct(data)}
              hqData={hqQueryResult}
              showSelectHQ={true}
              getFilteredValue={(value: string) => setFilteredValue(value)}
            />
          </FormModal>
        ) : null}
      </section>
    </LoaderContainer>
  );
};

export default TransactionDashboard;
