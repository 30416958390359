import React, { useState } from "react";
import { Formik, FormikValues, useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "src/components/Button";
import { FormInput } from "src/components/inputs";
import {
  handleNotification,
  SuccessNotification,
} from "src/helpers/helperFunction";

// YUP VALIDATION FOR ADD AMENITY
const AddAmenityValidation = Yup.object({
  name: Yup.string().label("Name").required(),
  // description: Yup.string().label("Description"),
  // price: Yup.number().label("Price"),
  // quantity: Yup.number().label("Quantity"),
});

const AddAmenityForm = ({ close }: { close: () => void }) => {
  // const [addAmenity, addAmenityResult] = useAddAmenityMutation();

  const handleSubmit = async (values: FormikValues) => {
    try {
      // const response = await addAmenity(values).unwrap();
      // if (response) {
      //   close();
      // }
      // SuccessNotification(response?.data?.message);
    } catch (error: any) {
      handleNotification(error);
    }
  };

  const Formik = useFormik<FormikValues>({
    initialValues: {
      name: "",
      description: "",
      price: 0,
      quantity: 0,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: AddAmenityValidation,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const styles =
    "h-[38px] py-6 rounded-lg w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-[#D9D9D9]";
  const labelStyles =
    "block mb-[6px] text-black text-start font-normal text-[14px] text-black my-6";
  console.log(Formik.errors);
  const FormData = [
    {
      id: "name",
      name: "Name",
      type: "text",
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.name,
      onBlur: Formik.handleBlur,
    },
    // {
    //   id: "description",
    //   name: "Description",
    //   type: "text",
    //   labelStyles: labelStyles,
    //   onChange: Formik.handleChange,
    //   value: Formik.values.description,
    //   onBlur: Formik.handleBlur,
    // },
    // {
    //   id: "price",
    //   name: "Price",
    //   type: "number",
    //   labelStyles: labelStyles,
    //   onChange: Formik.handleChange,
    //   value: Formik.values.price,
    //   onBlur: Formik.handleBlur,
    // },
    // {
    //   id: "quantity",
    //   name: "Quantity",
    //   type: "number",
    //   labelStyles: labelStyles,
    //   onChange: Formik.handleChange,
    //   value: Formik.values.quantity,
    //   onBlur: Formik.handleBlur,
    // },
  ];

  return (
    <form
      onSubmit={Formik.handleSubmit}
      className="w-full flex flex-col justify-center items-center px-4 pb-4"
    >
      <div className="grid grid-cols-1 w-full gap-x-2 content-center">
        {FormData.map((dt, i) => (
          <FormInput
            key={i}
            id={dt.id}
            name={dt.name}
            type={dt.type}
            styles={styles}
            labelStyles={dt.labelStyles}
            onChange={dt.onChange}
            value={dt.value}
            onBlur={dt.onBlur}
            // disabled={addAmenityResult.isLoading}
          />
        ))}
      </div>

      <Button
        text="Add Amenity"
        // disabled={addAmenityResult.isLoading}
        // showModal={addAmenityResult.isLoading}
        className="h-[41px] mt-6 font-bold bg-white border hover:bg-[#002E66] border-[#002E66] rounded-lg w-full hover:text-[#fff] text-[#002E66]"
        type="submit"
      />
    </form>
  );
};

export default AddAmenityForm;
