import { format } from "date-fns";
import React, { useMemo } from "react";
import { useGetAllTransactionsQuery } from "src/api/transactionsApiSlice";
import { TableLoader } from "src/components/LoaderContainer";
import ViewWalletComp from "src/components/ViewWalletComponent";
import { SOURCES, STATUS, TYPES } from "src/helpers/Constant";
import { TransactionsType } from "src/helpers/alias";
import { Category, CurrencyFormatter } from "src/helpers/helperFunction";
import { Badge } from "src/components/Badge";
import { HeadCellTypes } from "./AllTransactions";

export default function StationTransactions({
  handleRowClick,
  handleChangePage,
  debouncedValue,
  info,
  value,
  startDate,
  endDate,
}: any) {
  const headCells: readonly HeadCellTypes[] = [
    {
      id: "created",
      minWidth: 170,
      label: "Transaction Date",
    },
    {
      id: "recieverName",
      minWidth: 170,
      label: "Station Name",
    },
    {
      id: "amount",
      minWidth: 170,
      label: "Transaction Amount (N)",
    },

    {
      id: "referenceId",
      minWidth: 170,
      label: "Reference ID",
    },

    {
      id: "category",
      minWidth: 190,
      label: "Category",
    },
    {
      id: "type",
      minWidth: 190,
      label: "Type",
    },

    {
      id: "status",
      minWidth: 170,
      label: "Status",
    },
  ];

  const allTransactionsResult = useGetAllTransactionsQuery({
    who: "stations",
    for: value?.for,
    ...(STATUS.includes(info?.source) && { status: info.source }),
    ...(SOURCES.includes(info?.source) && { source: info.source }),
    ...(TYPES.includes(info?.source) && { type: info.source }),
    ...(info?.page !== 0 && { page: info?.page }),
    ...(debouncedValue !== "" && { search: debouncedValue }),
    ...(startDate &&
      endDate && {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      }),
  });

  const handledAPIResponse = useMemo(() => {
    const transactions = allTransactionsResult?.currentData?.transactions || [];

    return transactions?.data?.map((cur: any) => {
      const tdata = {
        created: format(new Date(cur.createdAt), "d/MM/yyyy hh:mm:ss"),
        name: cur?.stationBranch?.name || "----------",
        recieverName:
          cur?.meta.recieverName || cur?.meta.payerName || "----------",
        amount: CurrencyFormatter(Number(cur?.amount)),
        doneby: cur?.meta?.payerName || "----------",
        referenceId: cur?.meta?.reference || "----------",
        category: cur?.category ? (
          <Badge type={"TRANSACTION_CATEGORY_OPTIONS"} status={cur?.category} />
        ) : (
          "----------"
        ),
        type: cur?.type ? (
          <Badge type={"TRANSACTION_TYPE_OPTIONS"} status={cur?.type} />
        ) : (
          "----------"
        ),
        status: cur?.status ? (
          <Badge type={"TRANSACTION_STATUS_OPTIONS"} status={cur?.status} />
        ) : (
          "----------"
        ),
      };
      return { ...tdata, previewData: tdata };
    });
  }, [allTransactionsResult]);

  const props = {
    rows: handledAPIResponse || [],
    headCells,
    handleRowClick,
    accountInformation: {
      balance: 0,
      amountIn: 0,
      amountOut: 0,
    },
    handleChangePage,
    paginationData: {
      totalPage: allTransactionsResult?.currentData?.transactions?.totalPages,
      limit: allTransactionsResult?.currentData?.transactions?.limit,
      page: allTransactionsResult?.currentData?.transactions?.page,
    },
  };

  return (
    <section>
      <TableLoader
        data={allTransactionsResult}
        tableData={handledAPIResponse || []}
      >
        <ViewWalletComp {...props} />
      </TableLoader>
    </section>
  );
}
