import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from "react";
import AdminProfile from "src/assets/img/AdminProfile.svg";
import ManageWebsites from "src/assets/img/ManageWebsite.svg";
import ManageAdmins from "src/assets/img/ManageAdmin.svg";
import ResetPasword from "src/assets/img/ResetPasword.svg";
import { CardButton } from "src/components/Card";
import { cardBtnType, ErrorType } from "src/helpers/alias";
import { APP_ROUTE, PERMISSION } from "src/helpers/Constant";
import {
  useGetAdminQuery,
  useUpdateAdminMutation,
  useUpdateOwnProfileAdminMutation,
} from "src/api/setttingsApislice";
import ProfileCard from "src/components/ProfileCard";
import ManageAdmin from "./ManageAdmin";
import { LoaderContainer } from "src/components/LoaderContainer";
import { FormModal } from "src/components/ModalComp";
import { Button } from "src/components/Button";
import * as Yup from "yup";
import { FormikValues, useFormik } from "formik";
import { FormInput, PasswordInput, SelectInput } from "src/components/inputs";
import { Upload } from "src/components/Upload";
import {
  convert2base64,
  handleNotification,
  SuccessNotification,
} from "src/helpers/helperFunction";
import Image from "src/components/Image";
import ManageWebsite from "./ManageWebsite";
import { useAuth } from "src/hooks/useAuth";
import { logOut, setUser } from "src/features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import AddRolePermission, {
  checkPermissions,
} from "src/components/PermissionRestrictorComp";
import { useAppDispatch } from "src/hooks/reduxhooks";
import { RefferalSetting } from "./RefferalSetting";

const HQData: any[] = [
  {
    id: 1,
    icon: AdminProfile,
    name: "Profile",
    link: APP_ROUTE.ADMIN_PROFILE,
    priviledges: [
      PERMISSION.SYSTEM_ADMIN,
      PERMISSION.FEEDS,
      PERMISSION.TRANSACTION,
      PERMISSION.SUBADMIN,
    ],
  },

  {
    id: 2,
    icon: ManageAdmins,
    name: "Manage Admin",
    link: APP_ROUTE.MANAGE_ADMIN,
    priviledges: [PERMISSION.SYSTEM_ADMIN],
  },
  {
    id: 5,
    icon: ResetPasword,
    name: "Update Profile",
    link: APP_ROUTE.RESET_PASSWORD,
    priviledges: [
      PERMISSION.SYSTEM_ADMIN,
      PERMISSION.FEEDS,
      PERMISSION.TRANSACTION,
      PERMISSION.SUBADMIN,
    ],
  },

  {
    id: 3,
    icon: ManageWebsites,
    name: "Manage Website",
    link: APP_ROUTE.MANAGE_WEBSITE,
    priviledges: [PERMISSION.SYSTEM_ADMIN],
  },
  {
    id: 4,
    icon: ResetPasword,
    name: "Update Password",
    link: APP_ROUTE.RESET_PASSWORD,
    priviledges: [
      PERMISSION.SYSTEM_ADMIN,
      PERMISSION.FEEDS,
      PERMISSION.TRANSACTION,
      PERMISSION.SUBADMIN,
    ],
  },
  {
    id: 5,
    icon: ResetPasword,
    name: "Referal Settings",
    link: APP_ROUTE.REFFERALS,
    priviledges: [PERMISSION.SYSTEM_ADMIN],
  },
];
const Settings = () => {
  const [cardName, setName] = useState<string>("profile");

  const [showModal, setShowAddModal] = useState<boolean>(false);
  const [showReferalSettings, setShowReferalSettings] =
    useState<boolean>(false);
  const [showPasswordModal, setShowPassword] = useState(false);
  const { user } = useAuth();
  const adminResult = useGetAdminQuery(user?.id, { skip: !user?.id });

  const handledAPIResponse = useMemo(() => {
    const hqProfile = adminResult?.data?.data;

    return {
      profile: {
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        role: user?.role,
        phoneNumber: user?.phoneNumber,
      },
      id: user?.id,
      avatar: hqProfile?.avatar,
    };
  }, [adminResult]);

  function CloseModal() {
    setShowAddModal((prevState) => !prevState);
  }
  function CloseRefferalModal() {
    setShowReferalSettings((prevState) => !prevState);
  }
  function ClosePasswordModal() {
    setShowPassword((prevState) => !prevState);
  }

  return (
    <section>
      <article>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4  py-3">
          <>
            {HQData.map((dt) => (
              <>
                <AddRolePermission
                  rolePermission={dt?.priviledges}
                  empty={true}
                >
                  <Fragment>
                    <CardButton
                      name={dt.name}
                      icon={dt.icon}
                      link={dt.link}
                      activeBtn={cardName}
                      height={"98px"}
                      onClick={() => {
                        switch (dt?.name.toLowerCase()) {
                          case "update profile":
                            setShowAddModal((prevState) => !prevState);
                            break;
                          case "referal settings":
                            setShowReferalSettings((prevState) => !prevState);
                            break;
                          case "update password":
                            setShowPassword((prevState) => !prevState);
                            break;

                          default:
                            setName(dt.name);
                            break;
                        }
                        // dt?.name.toLowerCase() === "update profile"
                        // 	? setShowAddModal(true)
                        // 	: setName(dt.name);
                      }}
                    />
                  </Fragment>
                </AddRolePermission>
              </>
            ))}
          </>
        </div>
        <LoaderContainer data={adminResult}>
          {cardName.toLowerCase() === "profile" ? (
            <ProfileCard
              showBanner={false}
              data={handledAPIResponse.profile || {}}
              imageURL={handledAPIResponse.avatar?.url || ""}
              showImage={true}
            />
          ) : null}

          {cardName.toLowerCase() === "manage admin" ? <ManageAdmin /> : null}
          <AddRolePermission
            rolePermission={[PERMISSION.ENGINEER, PERMISSION.SYSTEM_ADMIN]}
            empty={true}
          >
            {cardName.toLowerCase() === "manage website" ? (
              <ManageWebsite />
            ) : (
              <div></div>
            )}
          </AddRolePermission>
          {showModal ? (
            <ResetProfile
              close={CloseModal}
              data={handledAPIResponse}
              onResponse={() => adminResult.refetch()}
            />
          ) : null}
          {showReferalSettings ? (
            <RefferalSetting
              close={CloseRefferalModal}
              data={handledAPIResponse}
              onResponse={() => adminResult.refetch()}
            />
          ) : null}
          {showPasswordModal ? (
            <ResetPassword
              close={ClosePasswordModal}
              data={handledAPIResponse}
            />
          ) : null}
        </LoaderContainer>
      </article>
    </section>
  );
};

export default Settings;

const AddbranchValidation = Yup.object({
  firstName: Yup.string().label("First name").required(),
  lastName: Yup.string().label("Last name").required(),
  phoneNumber: Yup.string()
    .label("phone number")
    // .length(, "invalid")
    .required(),
  email: Yup.string().label("email").email().required(),
  role: Yup.string<"super_admin" | "sub_admin">().defined(),
});

const ChangePasswordValidation = Yup.object({
  oldPassword: Yup.string().label("Old password").required(),
  password: Yup.string().label("Password").required(),
  confirmPassword: Yup.string().label("Password"),
  avatar: Yup.string().notRequired(),
  id: Yup.string().notRequired(),
});

/**
 * 1.⁠ ⁠ENGINEER
-Can do and undo

2.SUPER
-Can do all

3.⁠ ⁠ADMIN
-Can do all except “manage admin”

4.⁠ ⁠TRANSACTION
-Can view transactions
-Can view user profile
-Can send Notification
-Can reply support 
5.⁠ ⁠HQ Admin -can add HQ, depots, branches, suspend HQ, send notification, view transaction
7.⁠ ⁠Feed and notification admin- Can perform all feeds and quick fix action View, Add, Update, Delete and send notification to all users
 * 
 * 
 * 
 * 
 */

const ResetPassword = (props: {
  close: () => void;
  data: { [index: string]: string | number | any };
}) => {
  const [updateAdmin, addNewResult] = useUpdateAdminMutation();
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  async function addNewAdmin(values: FormikValues) {
    try {
      const response = await updateAdmin(values).unwrap();
      logOut();
      navigate(APP_ROUTE.LOGIN);
      props.close();

      SuccessNotification(response?.status);
    } catch (error: ErrorType | any) {
      props.close();
      handleNotification(error);
    }
  }

  const Formik = useFormik<FormikValues>({
    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",

      id: props.data?.id,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ChangePasswordValidation,
    onSubmit: (values) => {
      addNewAdmin(values);
    },
  });
  const styles =
    "h-[38px] py-6 rounded-lg w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-[#D9D9D9]";
  const labelStyles =
    "block mb-[6px] text-black text-start font-normal text-[14px] text-black ml-0 my-1";

  const FormData = [
    {
      id: "oldPassword",
      name: "Old password",
      type: "text",
      styles: `${styles} ${
        Formik.errors.confirmPassword && Formik.touched.confirmPassword
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.oldPassword,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      error: Formik.errors.oldPassword,
      touched: Formik.touched.oldPassword,
    },
    {
      id: "password",
      name: "New password",
      type: "text",
      styles: `${styles} ${
        Formik.errors.password && Formik.touched.password
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.password,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      error: Formik.errors.password,
      touched: Formik.touched.password,
    },
    {
      id: "confirmPassword",
      name: "Confirm password",
      type: "text",
      styles: `${styles} ${
        Formik.errors.password && Formik.touched.password
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: () =>
        Formik.setFieldValue("confirmPassword", Formik.values.password),
      value: Formik.values.confirmPassword,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      // error: Formik.errors.confirmPassword,
      // touched: Formik.touched.confirmPassword,
    },
  ];

  return (
    <FormModal name="Update Admin Info" onClick={props.close}>
      <form
        onSubmit={Formik.handleSubmit}
        className="w-full flex flex-col justify-center items-center px-4 h-full overflow-y-auto"
      >
        <div className="grid grid-cols-1 w-full gap-x-2 content-center pt-4">
          {FormData.map((_v, i) => (
            <PasswordInput
              width="w-full"
              id={_v.id}
              name={_v.name}
              type={"text"}
              styles={_v.styles}
              labelStyles={_v.labelStyles}
              onChange={_v.onChange}
              value={_v.value}
              onBlur={_v.onBlur}
              disabled={_v.disabled}
              // error={_v.error}
              // touched={_v.touched}
            />
          ))}
        </div>

        <Button
          text="Submit"
          disabled={addNewResult?.isLoading}
          showModal={addNewResult?.isLoading}
          className="h-11 mt-5 font-bold text-white rounded-lg px-5 bg-[#002E66] w-full flex items-center flex-row justify-center"
          type="submit"
        />
      </form>
      {/* </div>
			</div> */}
    </FormModal>
  );
};

const ResetProfile = (props: {
  close: () => void;
  onResponse: any;
  data: { [index: string]: string | number | any };
}) => {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const [updateOwnProfileAdmin, addNewResult] =
    useUpdateOwnProfileAdminMutation();
  const [step, setStep] = useState(0);

  async function addNewAdmin(values: FormikValues) {
    try {
      const response = await updateOwnProfileAdmin({
        ...values,
      }).unwrap();
      if (response) {
        console.log(response);
        dispatch(setUser(response.data));
        props.onResponse();
        return;
      }
      SuccessNotification(response?.status);
    } catch (error: ErrorType | any) {
      props.close();
      handleNotification(error);
    }
  }

  const Formik = useFormik<FormikValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      role: "super_admin",
      avatar: "",
      id: props.data?.id,
      accountStatus: {
        status: "confirmed",
        reason: "...",
      },
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: AddbranchValidation,
    onSubmit: (values) => {
      addNewAdmin(values);
    },
  });

  console.log(props.data?.id);
  const styles =
    "h-[38px] py-6 rounded-[38px] w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-[#D9D9D9]";
  const labelStyles =
    "block mb-[6px] text-black text-start font-normal text-[14px] text-black ml-0 my-1";

  const FormData = [
    {
      id: "firstName",
      name: "Admin's firstname",
      type: "text",
      styles: `${styles} ${
        Formik.errors.firstName && Formik.touched.firstName
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.firstName,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      error: Formik.errors.firstName,
      touched: Formik.touched.firstName,
    },
    {
      id: "lastName",
      name: "Admin's lastname",
      type: "text",
      styles: `${styles} ${
        Formik.errors.lastName && Formik.touched.lastName
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.lastName,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      error: Formik.errors.lastName,
      touched: Formik.touched.lastName,
    },
    {
      id: "email",
      name: "Admin's email",
      type: "email",
      styles: `${styles} ${
        Formik.errors.email && Formik.touched.email
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.email,
      onBlur: Formik.handleBlur,

      disabled: addNewResult.isLoading,
      error: Formik.errors.email,
      touched: Formik.touched.email,
    },
    {
      id: "phoneNumber",
      name: "Admin's phone number",
      type: "text",
      styles: `${styles} ${
        Formik.errors.phoneNumber && Formik.touched.phoneNumber
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.phoneNumber,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      error: Formik.errors.phoneNumber,
      touched: Formik.touched.phoneNumber,
    },
  ];

  // HANDLE IMAGE UPLOAD TO BASE 64
  async function uploadAvatar(e: { [index: string]: string | any }) {
    Formik.setFieldValue("avatar", await convert2base64(e.target.files[0]));
  }

  // Populate input with previous data
  useEffect(() => {
    if (!props.data) {
      return;
    }

    Formik.setValues({
      ...props.data.profile,
      avatar: props.data.profile.avatar,
      id: props.data.id,
    });

    return () => {
      Formik.setValues({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <FormModal name="Update Admin Info" onClick={props.close}>
      <form
        onSubmit={Formik.handleSubmit}
        className="w-full flex flex-col justify-center items-center px-4 h-full overflow-y-auto"
      >
        {step === 0 ? (
          <div className="grid grid-cols-1 w-full gap-x-2 content-center">
            {FormData.map((_v, i) => (
              <FormInput
                width="w-full"
                id={_v.id}
                name={_v.name}
                type={"text"}
                styles={_v.styles}
                labelStyles={_v.labelStyles}
                onChange={_v.onChange}
                value={_v.value}
                onBlur={_v.onBlur}
                disabled={_v.disabled}
                // error={_v.error}
                // touched={_v.touched}
              />
            ))}
            {checkPermissions(auth?.user?.role || "", [
              PERMISSION.SYSTEM_ADMIN,
            ]) && (
              <SelectInput
                labelStyles={labelStyles}
                data={[
                  "super_admin",
                  "sub_admin",
                  "HQ_admin",
                  "transaction_admin",
                  "support_admin",
                  "feeds_admin",
                ]}
                disabled={addNewResult.isLoading}
                value={Formik.values.role}
                onChange={Formik.handleChange}
                name="Select role"
                id="role"
              />
            )}
          </div>
        ) : null}

        <div className="grid grid-cols-1 w-full gap-x-2 content-center pt-4">
          {Formik.values.avatar ? (
            <div>
              <Image
                image={Formik.values.avatar || ""}
                width={100}
                height={100}
                styles="mx-auto object-fit"
              />
            </div>
          ) : null}

          <div className="w-full h-24 mt-4">
            <Upload
              name="avatar"
              text="Profile picture"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                uploadAvatar(e);
              }}
            />
          </div>
        </div>

        <Button
          text="Submit"
          className="h-11 mt-5 w-full font-bold text-white rounded-lg px-5 bg-[#002E66] flex items-center flex-row justify-center"
          disabled={addNewResult?.isLoading}
          showModal={addNewResult?.isLoading}
          type="submit"
        />
      </form>
      {/* </div>
			</div> */}
    </FormModal>
  );
};
