import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetDepotListQuery } from "src/api/manageHQApiSlice";
import { Button } from "src/components/Button";
import { TableLoader } from "src/components/LoaderContainer";
import ViewWalletComp from "src/components/ViewWalletComponent";
import { SearchInput } from "src/components/inputs";
import { APP_ROUTE } from "src/helpers/Constant";
import { useDebounce } from "src/hooks/useDebounce";
import useHandleRowClick from "src/hooks/useHandleRowClick";

const headCells: any[] = [
  {
    id: "createdAt",
    minWidth: 170,
    label: "Reg Date",
  },
  {
    id: "name",
    minWidth: 170,
    label: "Depot name",
  },
  {
    id: "email",
    minWidth: 170,
    label: "Email",
  },
  {
    id: "address",
    minWidth: 170,
    label: "Address",
  },
  {
    id: "state",
    minWidth: 170,
    label: "State",
  },
  {
    id: "systemCode",
    minWidth: 170,
    label: "System code",
  },
  {
    id: "phoneNumber",
    minWidth: 170,
    label: "Phone number",
  },
];

export default function DepotList() {
  const [pagination, setPagination] = useState({ newPage: 0 });
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const { debouncedValue } = useDebounce(searchValue, 700);
  const navigate = useNavigate();

  const depotResult = useGetDepotListQuery({
    limit: 8,
    ...(debouncedValue && { search: debouncedValue }),
    ...(pagination?.newPage !== 0 &&
      !debouncedValue && { page: pagination.newPage }),
    ...(selectedState && { state: selectedState }),
  });

  const handledAPIResponse = useMemo(
    () =>
      depotResult?.data?.depots?.data?.reduce(
        (acc: any, curr: { createdAt: string | number | Date; logo: any }) => [
          ...acc,
          {
            ...curr,
            logo: curr?.logo?.url,
            createdAt: format(new Date(curr?.createdAt), "d-M-yyy h:m:s"),
          },
        ],
        []
      ),
    [depotResult]
  );

  const { handleRowClick } = useHandleRowClick(fn);

  function fn(data: { [index: string]: string | number }) {
    navigate(`/Depot/${data?.id}`, {
      state: data?.name,
    });
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination((prev) => {
      return { ...prev, newPage };
    });
  };

  const props = {
    rows: handledAPIResponse || [],
    headCells,
    handleRowClick,
    showImage: true,
    imageCell: "logo",
    handleChangePage,
    paginationData: {
      totalPage: depotResult?.data?.depots?.totalPages,
      limit: depotResult?.data?.depots?.limit,
      page: depotResult?.data?.depots?.page,
    },
  };

  return (
    <section>
      <article>
        <div className="mt-6">
          <div className="w-full flex items-center justify-between gap-2">
            <div className="flex   h-11 max-w-[562px] items-center gap-2 rounded-[15px] border-2 border-[#D0D5DD] bg-[#D9D9D9] px-[18px]">
              <SearchInput
                name="branch-search"
                placeholder="Search"
                value={searchValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const target = e.target;
                  setSearchValue(target.value);
                }}
              />
            </div>
            <div className="flex items-center  gap-2 w-full flex-row">
              <select
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
                className="border-2 border-gray-300 rounded-lg px-2 py-2 w-full max-w-52"
              >
                <option value=""> Filter by State</option>
                <option value="abuja">Abuja</option>
                <option value="lagos">Lagos</option>
                {/* Add more options as per your states */}
              </select>
            </div>
            <div className="h-11">
              <Button
                text="Add depot"
                className="h-full w-full min-w-52 rounded-full font-bold bg-primary text-white flex items-center justify-center px-10 py-4"
                type="button"
                showIcon={false}
                onClick={() => navigate(APP_ROUTE.DEPOT_CREATE)}
              />
            </div>
          </div>

          <div className="h-fit w-full bg-white mt-6 shadow-lg rounded-t-lg">
            <div className="h-full w-full bg-white flex justify-between items-center py-6 px-6"></div>
            <TableLoader
              data={depotResult}
              tableData={handledAPIResponse || []}
            >
              <ViewWalletComp {...props} />
            </TableLoader>
          </div>
        </div>
      </article>
    </section>
  );
}
