import React, { useState, useMemo } from "react";
import { ReactElement } from "react";
import { SearchInput } from "src/components/inputs";
import EnhancedTable from "src/components/Table";

import { Button } from "src/components/Button";

import { FormModal } from "src/components/ModalComp";
import { useNavigate } from "react-router-dom";
import useHandleSelectAllClick from "src/hooks/useHandleSelectAllClick";
import useHandleSingleSelect from "src/hooks/useHandleSingleSelect";
import useHandleRowClick from "src/hooks/useHandleRowClick";
import useIsSelected from "src/hooks/useIsSelected";
import {
  useExportAllHQQuery,
  useFetchAllHQQuery,
} from "src/api/manageHQApiSlice";
import { TableLoader } from "src/components/LoaderContainer";

import { useDebounce } from "src/hooks/useDebounce";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import AddNewHQ from "src/components/AddNewHQ";
import { SelectInput, SelectType } from "src/components/SelectInput";
import { enUS } from "date-fns/locale";
import { useFetchAllStatesQuery } from "src/api/statesApiSlice";

interface HeadCell {
  id: string;
  label: string | ReactElement;
  minWidth: number;
}
export const statesFilterData: SelectType[] = [
  { id: 1, value: "abuja", label: "Abuja" },
  { id: 2, value: "lagos", label: "Lagos" },
];

const headCells: readonly HeadCell[] = [
  {
    id: "createDate",
    minWidth: 220,
    label: "Reg date",
  },

  {
    id: "name",
    minWidth: 170,
    label: "Name",
  },
  {
    id: "email",
    minWidth: 170,
    label: "Email",
  },
  {
    id: "hqAddress",
    minWidth: 170,
    label: "HQ address",
  },
  {
    id: "status",
    minWidth: 170,
    label: "Status",
  },

  {
    id: "phoneNumber",
    minWidth: 170,
    label: "Phone number",
  },
  {
    id: "state",
    minWidth: 170,
    label: "State",
  },
];

type ProfileType = { [index: string]: string };

const ManageHQ = () => {
  const statesResult = useFetchAllStatesQuery(null);

  const handledStatesAPIResponse = useMemo(() => {
    const data = statesResult?.currentData || [];
    let neededData: {
      id: string;
      label: string;
      value: string;
    }[] = [];

    if (data) {
      for (const iterator of data) {
        neededData = [
          ...neededData,
          {
            id: iterator.id,
            label: iterator?.state?.name,
            value: iterator?.state?.name?.toLowerCase(),
          },
        ];
      }
    }
    return neededData;
  }, [statesResult]);
  const [filteredValue, setFilteredValue] = useState<string>("");
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [pagination, setPagination] = useState({ newPage: 1 });
  const [filterParam, setFilterParam] = useState({
    page: 0,
    state: "",
    search: "",
  });
  const navigate = useNavigate();
  const { debouncedValue: stateDebouncedValue } = useDebounce(
    filterParam.state,
    700
  );
  const { debouncedValue: searchdebouncedValue } = useDebounce(
    filterParam.search,
    700
  );

  const hqQueryResult = useFetchAllHQQuery({
    page: pagination.newPage,
    ...(stateDebouncedValue && {
      state: stateDebouncedValue,
    }),
    ...(searchdebouncedValue && {
      search: searchdebouncedValue,
    }),
  });

  const handleInputChange = (event: any) => {
    setFilterParam({ ...filterParam, search: event.target.value });
  };
  const handleStateChange = (event: { target: { value: string } }) => {
    setFilterParam({ ...filterParam, state: event.target.value });
  };
  const exportHqResult = useExportAllHQQuery({
    ...{ ...filterParam },
  });

  // hqQueryResult?.currentData?.hqProfile?.totalData;
  const handledAPIResponse = useMemo(() => {
    let neededData: ProfileType[] = [];
    const hqProfile = hqQueryResult?.currentData?.hqProfile;

    if (hqProfile) {
      for (const iterator of hqProfile?.data) {
        const {
          id,
          name,
          email,
          hqAddress,
          phoneNumber,
          state,
          createdAt,
          systemCode,
          wallets,
          logo,
          status: { status },
          admin,
        } = iterator;
        let createDate = format(new Date(createdAt), "do MMMM yyyy, hh:mm a", {
          locale: enUS,
        });
        neededData = [
          ...neededData,
          {
            createDate,
            id,
            name,
            email,
            logo: logo?.url,
            hqAddress,
            phoneNumber,
            state,
            systemCode,
            status,
            admin,
          },
        ];
      }
      return neededData;
    }
  }, [hqQueryResult]);

  const { handleSelectAllClick, selected, setSelected } =
    useHandleSelectAllClick(handledAPIResponse);

  const { handleClick } = useHandleSingleSelect(selected, setSelected);
  const { handleRowClick } = useHandleRowClick(fn);
  const { isSelected } = useIsSelected(selected);

  // API TO GET ALL HQ INFORMATION
  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination((prev) => {
      return { ...prev, newPage };
    });
  };

  // TABLE FILTER TAB
  // const filteredData = useMemo(() => {
  //   if (!selectedState) {
  //     return handledAPIResponse || [];
  //   }
  //   return (
  //     handledAPIResponse?.filter(
  //       (item) => item.state.toLowerCase() === selectedState.toLowerCase()
  //     ) || []
  //   );
  // }, [selectedState, handledAPIResponse]);

  function fn(data: { [index: string]: string | number }) {
    navigate(`/manage-HQ/${data?.name}`, {
      state: { name: data?.name, id: data?.id },
    });
  }
  let dataToChildren: any = {
    rows: handledAPIResponse || [],
    headCells,
    handleRowClick,
    isSelected,
    handleClick,
    handleSelectAllClick,
    selected,
    handleChangePage,
    paginationData: {
      totalPage: hqQueryResult?.currentData?.hqProfile?.totalPages,
      limit: hqQueryResult?.currentData?.hqProfile?.limit,
      page: hqQueryResult?.currentData?.hqProfile?.page,
    },
  };

  function closeAddHQModal(): void {
    setShowAddModal((prev) => !prev);
  }

  // HANDLE DATA EXPORT TO CSV

  const handleExportToCSV = useMemo(
    () => exportHqResult?.currentData?.hqProfile?.data,
    [exportHqResult]
  );

  return (
    <section>
      <article>
        <div className="flex flex-wrap gap-3 justify-between items-center mt-6 h-20 ">
          <div className="flex items-center gap-2">
            <SearchInput
              name="branch-search"
              placeholder="Search"
              value={filterParam.search}
              onChange={handleInputChange}
            />
            <div className="flex items-start gap-2 min-w-72 flex-row">
              <SelectInput
                filteredValue={filterParam?.state || ""}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setFilterParam({ ...filterParam, state: e.target.value })
                }
                iniText="All states"
                tabData={handledStatesAPIResponse}
              />
            </div>
          </div>

          <div className="w-fit flex items-center flex-wrap gap-2">
            <Button
              text="Create HQ"
              type="button"
              showIcon={true}
              onClick={() => setShowAddModal(true)}
            />
            <div className="w-[109px]  h-11">
              <CSVLink filename="HQ_data.csv" data={handleExportToCSV ?? []}>
                <Button
                  text="Export"
                  className="h-full w-full font-bold bg-[#D0D5DD] rounded-lg hover: text-[#002E66] flex items-center justify-center"
                  type="button"
                  showIcon={false}
                  onClick={() => console.log("add branch")}
                />
              </CSVLink>
            </div>
          </div>
        </div>
        <div className="h-fit w-full bg-[#f0f5f7]">
          <TableLoader
            data={hqQueryResult}
            tableData={handledAPIResponse || []}
          >
            <div className="h-full w-full">
              <div className="relative mt-10 lg:mt-10 xl:mt-0">
                <EnhancedTable
                  {...dataToChildren}
                  showImage={true}
                  imageCell="logo"
                />
              </div>
            </div>
          </TableLoader>

          {/* FLAG A HQ */}

          {showAddModal ? (
            <FormModal
              name="Create HQ"
              onClick={() => setShowAddModal((prevState) => !prevState)}
            >
              <AddNewHQ close={closeAddHQModal} />{" "}
            </FormModal>
          ) : null}
        </div>
      </article>
    </section>
  );
};

export default ManageHQ;
