import { API_ROUTE, RTKTAG } from "src/helpers/Constant";
import { apiSlice } from "./apiSlice";
import { InvalidateTag, providesTagList } from "src/helpers/helperFunction";

export const walletAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchHqWallet: builder.query({
      query: (args: { id: string }) =>
        ({
          url: `${API_ROUTE.FETCH_HQ_WALLET}/${args.id}`,
          method: "GET",
        } as any),
      providesTags: [{ type: RTKTAG.MANAGE_HQ }],
    }),
    lockHqWallet: builder.mutation({
      query: ({
        id,
        ...body
      }: {
        id: string;
        locked?: boolean;
        canWithdraw?: boolean;
      }) =>
        ({
          url: `${API_ROUTE.LOCK_HQ_WALLET}/${id}`,
          method: "PATCH",
          body,
        } as any),
      invalidatesTags: (result) =>
        InvalidateTag(result?.data?.id, RTKTAG.MANAGE_HQ) as any,
    }),
    lockBranchWallet: builder.mutation({
      query: ({
        id,
        ...body
      }: {
        id: string;
        locked?: boolean;
        canWithdraw?: boolean;
      }) =>
        ({
          url: `${API_ROUTE.LOCK_BRANCH_WALLET}/${id}`,
          method: "PATCH",
          body,
        } as any),
      invalidatesTags: (result) =>
        InvalidateTag(result?.data?.id, RTKTAG.MANAGER_BRANCH) as any,
    }),
    lockUserWallet: builder.mutation({
      query: ({
        id,
        ...body
      }: {
        id: string;
        locked?: boolean;
        canWithdraw?: boolean;
      }) =>
        ({
          url: `${API_ROUTE.LOCK_USER_WALLET}/${id}`,
          method: "PATCH",
          body,
        } as any),
      invalidatesTags: (result) =>
        InvalidateTag(result?.data?.id, RTKTAG.USERS) as any,
    }),
  }),
});
export const {
  useFetchHqWalletQuery,
  useLockBranchWalletMutation,
  useLockHqWalletMutation,
  useLockUserWalletMutation,
} = walletAPISlice;
