import { FormikValues, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useUpdateAdminMutation } from "src/api/setttingsApislice";
import { Button } from "src/components/Button";
import { FormInput, SelectInput } from "src/components/inputs";
import { FormModal } from "src/components/ModalComp";
import { checkPermissions } from "src/components/PermissionRestrictorComp";
import { setUser } from "src/features/auth/authSlice";
import { ErrorType } from "src/helpers/alias";
import { PERMISSION } from "src/helpers/Constant";
import {
  convert2base64,
  handleNotification,
  SuccessNotification,
} from "src/helpers/helperFunction";
import {
  AddbranchValidation,
  RefferalValidation,
} from "src/helpers/YupValidation";
import { useAppDispatch } from "src/hooks/reduxhooks";
import { useAuth } from "src/hooks/useAuth";
import * as Yup from "yup";

const RefferalSettingValidation = Yup.object({
  amount: Yup.string().required().label("Amount"),
  status: Yup.string<"disabled" | "enabled">().defined().label("Password"),
});

export const RefferalSetting = (props: {
  close: () => void;
  onResponse: any;
  data: { [index: string]: string | number | any };
}) => {
  const dispatch = useAppDispatch();
  const [updateAdmin, addNewResult] = useUpdateAdminMutation();
  const [step, setStep] = useState(0);

  // async function addNewAdmin(values: FormikValues) {
  //   try {
  //     const response = await updateAdmin(values).unwrap();
  //     if (response) {
  //       console.log(response);
  //       dispatch(setUser(response.data));
  //       props.onResponse();
  //       return;
  //     }
  //     SuccessNotification(response?.status);
  //   } catch (error: ErrorType | any) {
  //     props.close();
  //     handleNotification(error);
  //   }
  // }

  const Formik = useFormik<FormikValues>({
    initialValues: {
      amount: "",
      status: "disabled",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: RefferalSettingValidation,
    onSubmit: (values) => {
      // addNewAdmin(values);
    },
  });

  const styles =
    "h-[38px] py-6 rounded-[38px] w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-[#D9D9D9]";
  const labelStyles =
    "block mb-[6px] text-black text-start font-normal text-[14px] text-black ml-0 my-1";

  const FormData = [
    {
      id: "amount",
      name: "Refferal amount",
      type: "text",
      styles: `${styles} ${
        Formik.errors.amount && Formik.touched.amount
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.amount,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      error: Formik.errors.amount,
      touched: Formik.touched.amount,
    },
  ];

  // HANDLE IMAGE UPLOAD TO BASE 64
  async function uploadAvatar(e: { [index: string]: string | any }) {
    Formik.setFieldValue("avatar", await convert2base64(e.target.files[0]));
  }

  // // Populate input with previous data
  // // useEffect(() => {
  // //   if (!props.data) {
  // //     return;
  // //   }

  //   // Formik.setValues({
  //   //   ...props.data.profile,
  //   //   avatar: props.data.profile.avatar,
  //   //   id: props.data.id,
  //   // });

  //   return () => {
  //     Formik.setValues({ amount: 0, status: "disabled" });
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props]);

  return (
    <FormModal name="Update Refferal Info" onClick={props.close}>
      <form
        onSubmit={Formik.handleSubmit}
        className="w-full flex flex-col justify-center items-center px-4 h-full overflow-y-auto"
      >
        {step === 0 ? (
          <div className="grid grid-cols-1 w-full gap-x-2 content-center">
            {FormData.map((_v, i) => (
              <FormInput
                width="w-full"
                id={_v.id}
                name={_v.name}
                type={"text"}
                styles={_v.styles}
                labelStyles={_v.labelStyles}
                onChange={_v.onChange}
                value={_v.value}
                onBlur={_v.onBlur}
                disabled={_v.disabled}
                // error={_v.error}
                // touched={_v.touched}
              />
            ))}

            <SelectInput
              labelStyles={labelStyles}
              data={["disabled", "enabled"]}
              disabled={addNewResult.isLoading}
              value={Formik.values.status}
              onChange={Formik.handleChange}
              name="Select Status"
              id="status"
            />
          </div>
        ) : null}

        <Button
          text="Submit"
          className="h-11 mt-5 w-full font-bold text-white rounded-lg px-5 bg-[#002E66] flex items-center flex-row justify-center"
          disabled={addNewResult?.isLoading}
          showModal={addNewResult?.isLoading}
          type="submit"
        />
      </form>
      {/* </div>
              </div> */}
    </FormModal>
  );
};
