import { API_ROUTE, RTKTAG } from "src/helpers/Constant";
import { apiSlice } from "./apiSlice";
import { providesTagList } from "src/helpers/helperFunction";
export const manageBranAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllBranch: builder.query({
      query: (params) =>
        ({
          url: `${API_ROUTE.GET_ALL_BRANCH}?orderBy=createdAt:desc`,
          method: "GET",
          params,
        } as any),

      providesTags: (result) => [
        { type: RTKTAG.MANAGER_BRANCH },
        { type: RTKTAG.MANAGE_HQ },
      ],
    }),
    exportAllBranch: builder.query({
      query: (params) => API_ROUTE.GET_ALL_BRANCH,
      providesTags: [
        { type: RTKTAG.MANAGER_BRANCH },
        { type: RTKTAG.MANAGE_HQ },
      ],
    }),
    fetchBranch: builder.query({
      query: (args: { id: string; params: any }) =>
        ({
          url: `${API_ROUTE.FETCH_BRANCH}/${args.id}`,
          method: "GET",
          params: args.params,
        } as any),
      providesTags: [
        { type: RTKTAG.MANAGER_BRANCH },
        { type: RTKTAG.MANAGE_HQ },
      ],
    }),
    updateBranchReviewStatus: builder.mutation({
      query: (args: { branch: string; params: any; body: any }) =>
        ({
          url: `${API_ROUTE.UPDATE_BRANCH_REVIEW_STATUS}/${args.branch}`,
          method: "PATCH",
          params: args.params,
          body: args.body,
        } as any),
      invalidatesTags: [
        { type: RTKTAG.MANAGER_BRANCH },
        { type: RTKTAG.MANAGE_HQ },
      ],
    }),
    addNewBranch: builder.mutation({
      query: (body): any => ({
        url: API_ROUTE.ADD_NEW_BRANCH,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        { type: RTKTAG.MANAGER_BRANCH },
        { type: RTKTAG.MANAGE_HQ },
      ],
    }),
    addNewHQBranch: builder.mutation({
      query: (body): any => ({
        url: API_ROUTE.ADD_HQ_BRANCH,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        { type: RTKTAG.MANAGER_BRANCH },
        { type: RTKTAG.MANAGE_HQ },
      ],
    }),
    changeStatus: builder.mutation({
      query: ({ id, ...body }): any => ({
        url: `${API_ROUTE.ADD_NEW_BRANCH}/${id}`,
        method: "PATCH",
        body: { ...body },
      }),
      invalidatesTags: [
        { type: RTKTAG.MANAGER_BRANCH },
        { type: RTKTAG.MANAGE_HQ },
      ],
    }),
  }),
});
export const {
  useFetchAllBranchQuery,
  useAddNewBranchMutation,
  useFetchBranchQuery,
  useAddNewHQBranchMutation,
  useExportAllBranchQuery,
  useChangeStatusMutation,
  useUpdateBranchReviewStatusMutation,
} = manageBranAPISlice;
