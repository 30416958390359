import React, { ReactElement } from "react";
import { PERMISSION } from "src/helpers/Constant";
import { useAuth } from "src/hooks/useAuth";

type AddRolePermissionType = {
  rolePermission: string[] | any;
  empty?: boolean;
  children: React.ReactNode | any;
  appPermission?: { [x: string]: string | any };
};

const AddRolePermission = ({
  appPermission = PERMISSION,
  rolePermission,
  children,
  empty = false,
}: AddRolePermissionType) => {
  const { user } = useAuth();

  // filter provided permission by the list of permission required in the app.
  const isPermissionInList = Object.values(appPermission).filter((perm) =>
    rolePermission.find((role: string) => perm.includes(role))
  );

  // check if the user permission includes the list of the expected permision

  let hasPermission = isPermissionInList.some((role: string) =>
    role?.includes(user?.role || "")
  );

  if (!!hasPermission) {
    return children;
  }
  return (
    !empty && (
      <div className="mt-10">Your are not permitted to access this page</div>
    )
  );
};

export default AddRolePermission;

export const checkPermissions = (
  userRole: string,
  rolePermissions: string[],
  appPermissions = PERMISSION
): boolean => {
  // Filter provided permission by the list of permission required in the app
  const isPermissionInList = Object.values(appPermissions).filter((perm) =>
    rolePermissions.includes(perm)
  );

  // Check if the user permission includes the list of the expected permission
  return isPermissionInList.some((perm: string) => perm.includes(userRole));
};
