import { ChangeEventHandler, ReactElement, useMemo, useState } from "react";
import React from "react";
import { Button } from "src/components/Button";
import useHandleRowClick from "src/hooks/useHandleRowClick";
import ViewWalletComp from "src/components/ViewWalletComponent";
import { SearchInput } from "src/components/inputs";
import { FilterList } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { enUS } from "date-fns/locale";
import user from "src/assets/img/User.svg";
import {
  useExportAllUserQuery,
  useFetchAllUserQuery,
} from "src/api/manageUserApi";
import { TableLoader } from "src/components/LoaderContainer";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import { SELECT_OPTIONS } from "src/helpers/Constant";
import { useDebounce } from "src/hooks/useDebounce";
import { useFetchAllStatesQuery } from "src/api/statesApiSlice";
import { SelectInput } from "src/components/SelectInput";
import { DashboardCards } from "src/components/Card";

interface HeadCellTypes {
  id: string;
  label: string;
  numeric?: boolean | null;
  minWidth: number;
  amount?: string | number;
  type?: string;
  status?: string | ReactElement | any;
  referenceId?: string | number;
  doneby?: string;
}

const headCells: readonly HeadCellTypes[] = [
  {
    id: "firstName",
    minWidth: 120,
    label: "First name",
  },
  {
    id: "lastName",
    minWidth: 100,
    label: "Last name",
  },
  {
    id: "email",
    minWidth: 170,
    label: "Email",
  },
  {
    id: "gender",
    minWidth: 70,
    label: "Gender",
  },
  {
    id: "systemCode",
    minWidth: 130,
    label: "System Code",
  },
  {
    id: "accountStatus",
    minWidth: 130,
    label: "Account Status",
  },
  {
    id: "phoneNumber",
    minWidth: 170,
    label: "Phone number",
  },
  {
    id: "created",
    minWidth: 210,
    label: "Reg Date",
  },
];

type SelectType = {
  id: string | number;
  value: string;
  label: string;
  [x: string]: any;
};

const Transactions = () => {
  const [pagination, setPagination] = useState({ newPage: 0 });

  const [filterParam, setFilterParam] = useState({
    search: "",
    gender: "",
    state: "",
  });

  const { debouncedValue: stateDebouncedValue } = useDebounce(
    filterParam.state,
    700
  );
  const { debouncedValue: searchdebouncedValue } = useDebounce(
    filterParam.search,
    700
  );
  const { debouncedValue: genderDebouncedValue } = useDebounce(
    filterParam.gender,
    700
  );
  const statesResult = useFetchAllStatesQuery(null);

  const userResult = useFetchAllUserQuery({
    limit: 8,
    ...(pagination?.newPage !== 0 && { page: pagination.newPage }),
    ...(searchdebouncedValue && {
      search: searchdebouncedValue,
      systemCode: searchdebouncedValue,
    }),
    ...(genderDebouncedValue && { gender: genderDebouncedValue }),
    ...(stateDebouncedValue && { state: stateDebouncedValue }),
  });

  const exportUserResult = useExportAllUserQuery({});

  const handledStatesAPIResponse = useMemo(() => {
    const data = statesResult?.currentData || [];
    let neededData: {
      id: string;
      label: string;
      value: string;
    }[] = [];

    if (data) {
      for (const iterator of data) {
        neededData = [
          ...neededData,
          {
            id: iterator.id,
            label: iterator?.state?.name,
            value: iterator?.state?.name?.toLowerCase(),
          },
        ];
      }
    }
    return neededData;
  }, [statesResult]);

  const handledAPIResponse = useMemo(() => {
    let neededData: {
      id: string;
      created: string;
      firstName: string;
      lastName: string;
      systemCode: string;
      gender: "Male" | "Female";
      email: string;
      walletNumber: string;
      accountStatus: string;
      state?: string;
      profileImg: string;
      phoneNumber: string;
    }[] = [];
    const { data } = userResult?.currentData?.users || {};

    if (data) {
      for (const iterator of data) {
        neededData = [
          ...neededData,
          {
            id: iterator.id,
            created: format(
              new Date(iterator.createdAt),
              "do MMMM yyyy, hh:mm a",
              { locale: enUS }
            ),
            firstName: iterator.firstName,
            lastName: iterator.lastName,
            systemCode: iterator.systemCode,
            walletNumber: iterator?.wallets?.walletNumber,
            gender: iterator.gender,
            accountStatus: iterator?.accountStatus?.status,
            profileImg: iterator?.profilePicture?.url,
            email: iterator.email,
            phoneNumber: iterator.phoneNumber,
            state: iterator?.state,
          },
        ];
      }
    }

    // Apply filters
    // let filteredData = neededData;

    // if (stateFilter) {
    //   filteredData = filteredData.filter((item) =>
    //     item.residentialAddress
    //       ?.toLowerCase()
    //       ?.includes(stateFilter.toLowerCase())
    //   );
    // }
    // if (genderFilter) {
    //   filteredData = filteredData.filter(
    //     (item) => item.gender.toLowerCase() === genderFilter.toLowerCase()
    //   );
    // }

    // if (searchValue.trim() !== "") {
    //   const searchTerm = searchValue.toLowerCase();
    //   filteredData = filteredData.filter(
    //     (item) =>
    //       item.firstName.toLowerCase().includes(searchTerm) ||
    //       item.lastName.toLowerCase().includes(searchTerm)
    //   );
    // }

    return neededData;
  }, [userResult]);

  const { handleRowClick } = useHandleRowClick(fn);
  const navigate = useNavigate();

  function fn(data: { [index: string]: string | number }) {
    navigate(`/users/${data?.id}`, { state: data?.firstName });
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination((prev) => {
      return { ...prev, newPage };
    });
  };

  const handleExportData = () => {
    // Implement export logic here
  };
  console.log(userResult);
  return (
    <section>
      <article>
        <div className=" mt-6">
          <div className=" w-56 h-28 mb-10">
            <DashboardCards
              name={"Users"}
              icon={user}
              amount={userResult?.currentData?.users?.totalData}
            />
          </div>

          <div className=" flex items-center justify-between flex-wrap  gap-2 ">
            <div className="flex min-w-48  h-11  max-w-[562px] items-center gap-2 rounded-[15px] border-2 border-[#D0D5DD] bg-[#d9d9d9] px-[18px]">
              <SearchInput
                name="branch-search"
                placeholder="Search"
                value={filterParam.search || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const target = e.target;
                  setFilterParam({ ...filterParam, search: target.value });
                }}
              />
            </div>
            <div className="flex flex-row flex-wrap gap-2">
              <SelectInput
                filteredValue={filterParam?.state || ""}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setFilterParam({ ...filterParam, state: e.target.value })
                }
                iniText="All states"
                tabData={handledStatesAPIResponse}
              />
              <SelectInput
                filteredValue={filterParam?.gender || ""}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setFilterParam({ ...filterParam, gender: e.target.value })
                }
                iniText="All genders"
                tabData={SELECT_OPTIONS.GENDERS}
              />
              <div className="w-[109px] h-11">
                <CSVLink
                  filename="Users_list.csv"
                  data={handledAPIResponse ?? []}
                >
                  <Button
                    text="Export"
                    className="h-full w-full font-bold bg-[#D0D5DD] rounded-lg hover: text-[#002E66] flex items-center justify-center"
                    type="button"
                    showIcon={false}
                    onClick={handleExportData}
                  />
                </CSVLink>
              </div>
            </div>
          </div>
          <div className="h-fit w-full bg-white mt-6 shadow-lg rounded-t-lg">
            <div className="h-full w-full bg-white flex justify-state gap-5 items-center py-6 px-6"></div>
            <TableLoader data={userResult} tableData={handledAPIResponse || []}>
              <ViewWalletComp
                rows={handledAPIResponse || []}
                headCells={headCells}
                handleRowClick={handleRowClick}
                handleChangePage={handleChangePage}
                paginationData={{
                  totalPage: userResult?.currentData?.users?.totalPages,
                  limit: userResult?.currentData?.users?.limit,
                  page: userResult?.currentData?.users?.page,
                }}
                showImage={true}
                imageCell="profileImg"
                imageAlt="lastName"
                // accountInformation={{
                //   balance: 0,
                //   amountIn: 0,
                //   amountOut: 0,
                // }}
              />
            </TableLoader>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Transactions;
