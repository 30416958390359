const badgeStyles = {
  yellow100: "bg-yellow-100 text-yellow-800",
  green100: "bg-green-100 text-green-800",
  red100: "bg-red-100 text-red-800",
  blue100: "bg-blue-100 text-blue-800",
  gray100: "bg-gray-100 text-gray-800",
  purple100: "bg-purple-100 text-purple-800",
  teal100: "bg-teal-100 text-teal-800",
  orange100: "bg-orange-100 text-orange-800",
  pink100: "bg-pink-100 text-pink-800",
  indigo100: "bg-indigo-100 text-indigo-800",
  gray200: "bg-gray-200 text-gray-800",
  green200: "bg-green-200 text-green-800",
  red200: "bg-red-200 text-red-800",
  green300: "bg-green-300 text-green-900",
  gray300: "bg-gray-300 text-gray-900",
  yello300: "bg-yellow-300 text-yellow-900",
  blue300: "bg-[#002e66] text-white",
  gray400: "bg-gray-400 text-gray-900",
};

const TransactionStatusOptions: any = {
  pending: {
    label: "Pending",
    className: badgeStyles.yellow100,
  },
  successful: {
    label: "Successful",
    className: badgeStyles.blue300,
  },
  reversed: {
    label: "Reversed",
    className: badgeStyles.red100,
  },
};

const TransactionSourceOptions: any = {
  bank_transfer: {
    label: "Bank transfer",
    className: badgeStyles.green100,
  },
  available_balance: {
    label: "Available balance",
    className: badgeStyles.orange100,
  },
  instant_wallet: {
    label: "Instant wallet",
    className: badgeStyles.purple100,
  },
  cash: {
    label: "Cash",
    className: badgeStyles.blue300,
  },
};

const TransactionTypeOptions: any = {
  credit: {
    label: "Credit",
    className: badgeStyles.green100,
  },
  debit: {
    label: "Debit",
    className: badgeStyles.red100,
  },
};

const TransactionCategoryOptions: any = {
  in_app_transfer: {
    label: "App Transfer",
    className: badgeStyles.yellow100,
  },
  bank_transfer: {
    label: "Bank Transfer",
    className: badgeStyles.green100,
  },
  referral_bonus: {
    label: "Referral bonus",
    className: badgeStyles.orange100,
  },
  top_up: {
    label: "Top up",
    className: badgeStyles.yello300,
  },
  premium_feature: {
    label: "Premium feature",
    className: badgeStyles.teal100,
  },
  fuel_purchase: {
    label: "Fuel purchase",
    className: badgeStyles.purple100,
  },
  instant_wallet: {
    label: "Instant Wallet",
    className: badgeStyles.yellow100,
  },
  deposit: {
    label: "Deposit",
    className: badgeStyles.green300,
  },
  transfer: {
    label: "Transfer",
    className: badgeStyles.green100,
  },

  withdrawal: {
    label: "Withdrawal",
    className: badgeStyles.orange100,
  },
};

const OptionTypes = {
  TRANSACTION_CATEGORY_OPTIONS: TransactionCategoryOptions,
  TRANSACTION_STATUS_OPTIONS: TransactionStatusOptions,
  TRANSACTION_TYPE_OPTIONS: TransactionTypeOptions,
  TRANSACTION_SOURCES_OPTIONS: TransactionSourceOptions,
};

export const Badge = ({
  status,
  type,
}: {
  status: any;
  type:
    | "TRANSACTION_CATEGORY_OPTIONS"
    | "TRANSACTION_STATUS_OPTIONS"
    | "TRANSACTION_TYPE_OPTIONS"
    | "TRANSACTION_SOURCES_OPTIONS";
}) => {
  const normalizedStatus = status.toString().toLowerCase();

  const badge = OptionTypes[type][normalizedStatus] || {
    label: status,
    className: "bg-gray-100 text-gray-800",
  };

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium ${badge.className}`}
    >
      {badge.label}
    </span>
  );
};
