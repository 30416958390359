import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { useGetSingleUserTransactionsQuery } from "src/api/transactionsApiSlice";
import { TableLoader } from "src/components/LoaderContainer";
import { SOURCES, STATUS, TYPES } from "src/helpers/Constant";
import { TransactionsType } from "src/helpers/alias";
import { CurrencyFormatter, truncateString } from "src/helpers/helperFunction";
import { useDebounce } from "src/hooks/useDebounce";
import useHandleRowClick from "src/hooks/useHandleRowClick";
import { filterData } from "../Transactions/AllTransactions";
import ReceiptCard from "src/components/ReceiptCard";
import { SearchInput } from "src/components/inputs";
import { SelectInput } from "src/components/SelectInput";
import ViewWalletComp from "src/components/ViewWalletComponent";
import { Badge } from "src/components/Badge";
import { enUS } from "date-fns/locale";

const headCells: any[] = [
  {
    id: "created",
    minWidth: 220,
    label: "Transaction Date",
  },
  {
    id: "accountName",
    minWidth: 190,
    label: "Name",
  },
  {
    id: "amount",
    minWidth: 120,
    label: "Amount (N)",
  },
  {
    id: "type",
    minWidth: 90,
    label: "Type",
  },
  {
    id: "reference",
    minWidth: 170,
    label: "Reference",
  },
  {
    id: "category",
    minWidth: 170,
    label: "Category",
  },
  {
    id: "source",
    minWidth: 190,
    label: "Source",
  },
  {
    id: "status",
    minWidth: 100,
    label: "Status",
  },
];

export default function UserWallet({ id }: { id: string }) {
  const { showModal, setShowModal, handleRowClick } = useHandleRowClick(fn);
  const [filterValue, setFilterValue] = useState<string>("");
  const { debouncedValue } = useDebounce(filterValue, 700);
  const [startDate, setStartDate] = useState<Date | any>(null);
  const [endDate, setEndDate] = useState<Date | any | number>(null);

  const [info, setInfo] = useState({
    page: 0,
    source: "",
  });

  const [transactionData, setTransactionData] = useState<{}>({});

  function fn(data: { [index: string]: string | number }) {
    setTransactionData(data);
    setShowModal((prev) => !prev);
  }

  const handleSelectChange = (event: { target: { value: string } }) => {
    setInfo((prev) => {
      return { ...prev, source: event.target.value };
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setInfo((prev) => {
      return { ...prev, page: newPage };
    });
  };
  console.log(id);
  const allTransactionsResult = useGetSingleUserTransactionsQuery({
    who: "users",
    user: id,
    ...(STATUS.includes(info?.source) && { status: info.source }),
    ...(SOURCES.includes(info?.source) && { source: info.source }),
    ...(TYPES.includes(info?.source) && { type: info.source }),
    ...(info?.page !== 0 && {
      page: info?.page,
    }),
    ...(debouncedValue !== "" && {
      search: debouncedValue,
    }),
    ...(startDate && {
      startDate: format(new Date(startDate), "d/MM/yyyy"),
    }),
    ...(endDate && {
      endDate: format(new Date(endDate), "d/MM/yyyy"),
    }),
  });

  console.log(allTransactionsResult);
  const handledAPIResponse = useMemo(() => {
    const transactions = allTransactionsResult?.currentData?.transactions || [];

    return transactions?.data?.reduce(
      (
        acc: { tableData: { previewData: any } }[],
        cur: TransactionsType | any
      ) => {
        const tableData = {
          created: format(new Date(cur.createdAt), "do MMM yyyy, hh:mm a", {
            locale: enUS,
          }),
          reference: cur?.reference || "----------",
          accountName: `${
            cur.source === "instant_wallet"
              ? cur?.meta?.destinationHolderName || "----------"
              : cur?.type === "credit"
              ? cur?.meta?.recieverName || "----------"
              : cur?.type === "debit"
              ? cur?.meta?.senderName || "----------"
              : ""
          }`,
          amount: CurrencyFormatter(Number(cur?.amount)),
          category: cur?.category ? (
            <Badge
              type={"TRANSACTION_CATEGORY_OPTIONS"}
              status={cur?.category}
            />
          ) : (
            "----------"
          ),
          categoryType: cur?.category || cur?.source,
          source: cur?.source ? (
            <Badge type={"TRANSACTION_SOURCES_OPTIONS"} status={cur?.source} />
          ) : (
            "----------"
          ),
          type: cur?.type ? (
            <Badge type={"TRANSACTION_TYPE_OPTIONS"} status={cur?.type} />
          ) : (
            cur?.type || "----------"
          ),
          status: cur?.status ? (
            <Badge type={"TRANSACTION_STATUS_OPTIONS"} status={cur?.status} />
          ) : (
            cur?.status || "----------"
          ),
          previewData: {},
        };

        tableData.previewData = {
          created: tableData.created,
          reference: tableData.reference,
          accountName: tableData.accountName,
          amount: tableData.amount,
          category: tableData.category,
          categoryType: tableData.categoryType,
          source: tableData.source,
          type: tableData.type,
          status: tableData.status,
          message: cur?.meta?.message || "----------",
          purpose: truncateString(cur?.purpose, 50) || "----------",
          balanceAfterTransaction: cur?.balanceAfterTransaction
            ? CurrencyFormatter(cur?.balanceAfterTransaction)
            : "----------",
        };

        switch (cur.category || cur.source) {
          case "bank_transfer":
            tableData.previewData = {
              ...tableData.previewData,
              sendersName: cur?.meta?.senderName || "----------",
              sendersAccountNumber:
                cur?.meta?.senderAccountNumber || "----------",
              sendersBank: cur?.meta?.bankName || "----------",
              receiversName: cur?.meta?.recieverName || "----------",
              receiversSystemCode:
                cur?.meta?.receiverSystemCode || "----------",
              recieverWalletNumber:
                cur?.meta?.recieverWalletNumber || "----------",
              receiverWalletBank: cur?.meta?.receiverWalletBank || "----------",
            };
            break;
          case "instant_wallet":
            tableData.previewData = {
              ...tableData.previewData,
              sendersName: cur?.meta?.senderName || "----------",
              sendersSystemCode: cur?.meta?.senderSystemCode || "----------",
              receiversName: cur?.meta?.destinationHolderName || "----------",
              category: tableData.category,
              receiversSystemCode:
                cur?.meta?.receiverSystemCode || "----------",
              product: cur?.meta?.product || "----------",
              instantWalletCode:
                cur?.meta?.destinationWalletNumber || "----------",
              recieverWalletNumber:
                cur?.meta?.destinationWalletNumber || "----------",
            };
            break;
          case "withdrawal":
            tableData.previewData = {
              ...tableData.previewData,
              sendersName: cur?.meta?.senderName || "----------",
              sendersSystemCode: cur?.meta?.senderSystemCode || "----------",
              receiversName: cur?.meta?.receiverName || "----------",
              receiversAccountNumber:
                cur?.meta?.receiverAccountNumber || "----------",
              receiversBank: cur?.meta?.receiverBank || "----------",
            };
            break;
          case "referral_bonus":
            tableData.previewData = {
              ...tableData.previewData,
              accountName: cur?.meta?.destinationHolderName || "----------",
              accountSystemCode: cur?.meta?.accountSystemCode || "----------",
              nameOfPersonReferred:
                cur?.meta?.nameOfPersonReferred || "----------",
              systemCodeOfPersonReferred:
                cur?.meta?.systemCodeOfPersonReferred || "----------",
            };
            break;
          case "premium_feature":
            tableData.previewData = {
              ...tableData.previewData,
              branchName: cur?.meta?.branchName || "----------",
              branchSystemCode: cur?.meta?.branchSystemCode || "----------",
              nameOfFeatureAdded: cur?.meta?.nameOfFeatureAdded || "----------",
            };
            break;
          default:
            break;
        }

        return [...acc, { ...tableData }];
      },
      []
    );
  }, [allTransactionsResult]);

  const props = {
    rows: handledAPIResponse || [],
    headCells,
    handleRowClick,
    accountInformation: {
      balance: 0,
      amountIn: 0,
      amountOut: 0,
    },
    handleChangePage,
    paginationData: {
      totalPage: allTransactionsResult?.currentData?.transactions?.totalPages,
      limit: allTransactionsResult?.currentData?.transactions?.limit,
      page: allTransactionsResult?.currentData?.transactions?.page,
    },
  };

  return (
    <article>
      <div className="mt-3">
        <div className="w-full flex justify-between items-center">
          <h2 className="text-[24px] text-center font-bold">Transactions</h2>
        </div>
        <div className="h-full w-full bg-white mt-1 shadow-lg rounded-t-lg">
          <div className="h-full w-full flex justify-between items-center gap-3 py-6 px-6 flex-wrap">
            <SearchInput
              name="branch-search"
              placeholder="Search"
              value={filterValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const target = e.target;
                setFilterValue(() => target.value);
              }}
            />
            <div className="flex gap-4 h-full flex-wrap">
              <DatePicker
                className="border border-gray-200 rounded-lg px-2 py-2"
                placeholderText="Start date"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <DatePicker
                className="border border-gray-200 rounded-lg px-2 py-2"
                selected={endDate}
                placeholderText="End date"
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
              <SelectInput
                tabData={filterData}
                filteredValue={info.source}
                onChange={handleSelectChange}
              />
            </div>
          </div>
          <TableLoader
            data={allTransactionsResult}
            tableData={handledAPIResponse || []}
          >
            <ViewWalletComp {...props} />
          </TableLoader>
        </div>
      </div>
      {showModal ? (
        <ReceiptCard data={transactionData} setShowModal={setShowModal} />
      ) : null}
    </article>
  );
}
