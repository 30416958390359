import { API_ROUTE } from "src/helpers/Constant";
import { apiSlice } from "./apiSlice";

// https://pyro-staging-api.up.railway.app/api/v1/system-admin/transactions?limit=6&page=1&source=user_transfer&orderBy=createdAt:desc&who=stations&for=station_branch&populate=stationBranch&search=&startDate=2023-09-24&endDate=2023-09-30

export const settingsAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTransactions: builder.query({
      query: (params) =>
        ({
          url: `${API_ROUTE.FETCH_ALL_TRANSACTION}?orderBy=createdAt:desc`,
          params,
        } as any),
    }),
    getUserTransactions: builder.query({
      query: (params: any) =>
        ({
          url: `${API_ROUTE.FETCH_ALL_TRANSACTION}?orderBy=createdAt:desc`,
          params,
        } as any),
    }),
    getSingleUserTransactions: builder.query({
      query: (params: any) =>
        ({
          url: `${API_ROUTE.FETCH_ALL_TRANSACTION}?orderBy=createdAt:desc`,
          params,
        } as any),
    }),
    exportAllTransactions: builder.query({
      query: (params) =>
        `${API_ROUTE.FETCH_ALL_TRANSACTION}?source=${params?.source}&orderBy=createdAt:desc&who=${params.who}&for=${params.for}&populate=${params.populate}`,
    }),
  }),
});

export const {
  useGetAllTransactionsQuery,
  useExportAllTransactionsQuery,
  useGetUserTransactionsQuery,
  useGetSingleUserTransactionsQuery,
} = settingsAPISlice;
