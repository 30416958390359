import { BarChartComp } from "src/components/Chart";
import { datas } from "src/helpers/data";

import attendant from "src/assets/img/attendant.svg";
import admin from "src/assets/img/AdminProfile.svg";
import branches from "src/assets/img/branches.svg";
import users from "src/assets/img/Users.svg";
import user from "src/assets/img/User.svg";

import { Fragment, useMemo, useState } from "react";
import { DashboardCards, DashboardCardsButton } from "src/components/Card";
import { useDashboardInfoQuery } from "src/api/authApiSlice";
import { LoaderContainer } from "src/components/LoaderContainer";
import { FormType, cardType } from "src/helpers/alias";
import { Button } from "src/components/Button";
import { FormModal } from "src/components/ModalComp";
import AddNewHQ from "src/components/AddNewHQ";
import { useAddHqNewBranchMutation } from "src/hq-admin/hq-api/manageHqApiSlice";
import {
  SuccessNotification,
  handleNotification,
} from "src/helpers/helperFunction";
import { AddNewBranch } from "src/hq-admin/hq-pages/Manage-branch/Components";
import {
  useExportAllHQQuery,
  useFetchAllHQQuery,
} from "src/api/manageHQApiSlice";
import { useDebounce } from "src/hooks/useDebounce";
import { APP_ROUTE, PERMISSION } from "src/helpers/Constant";
import { useNavigate } from "react-router-dom";
import { checkPermissions } from "src/components/PermissionRestrictorComp";
import { useAuth } from "src/hooks/useAuth";
import SuperAdminDashboard from "./dashboards/SuperAdminDashboard";
import FeedsDashboard from "./dashboards/FeedsDashboard";
import TransactionDashboard from "./dashboards/TransactionDashboard";

// const cardBtnData: cardBtnType[] = [
// 	{
// 		id: 1,
// 		icon: branchBtn,
// 		name: "Branches",
// 		link: APP_ROUTE.BRANCHES,
// 	},
// 	{
// 		id: 2,
// 		icon: walletBtn,
// 		name: "View Wallet",
// 		link: APP_ROUTE.VIEW_WALLET,
// 	},
// ];
const Dashboard = () => {
  const auth = useAuth();
  const response = useDashboardInfoQuery("");
  const [showModal, setShowAddModal] = useState(false);
  const [AddHQBranch, addHqBranchResult] = useAddHqNewBranchMutation();
  const [showAddModal, setShowNewModal] = useState(false);
  // const allHQQuery = useExportAllHQQuery({});
  const [filteredValue, setFilteredValue] = useState<string>("");
  const navigate = useNavigate();

  const { debouncedValue } = useDebounce(filteredValue, 700);

  const hqQueryResult = useFetchAllHQQuery({
    query: debouncedValue,
  });

  // console.log("thomas testing", hqQueryResult);

  function handleToggleModal() {
    setShowAddModal((prevState) => !prevState);
  }
  const cardData: cardType[] = [
    // {
    // 	id: 1,
    // 	icon: attendant,
    // 	amount: response?.data?.data.stationHqs,
    // 	name: "Station HQs",
    // },
    // {
    // 	id: 2,
    // 	icon: attendant,
    // 	amount: response?.data?.data.stationBranches,
    // 	name: "Station Branches",
    // }, name="HQ stations"
    {
      id: 1,
      icon: attendant,
      amount: response?.data?.data?.stationHq?.totalCount || 0,
      name: "HQ stations",
    },
    {
      id: 2,
      icon: user,
      amount: response?.data?.data?.users || 0,
      name: "Users",
    },
    {
      id: 3,
      icon: branches,
      amount: response?.data?.data?.stationBranch?.totalCount || 0,
      name: "Station Branches",
    },
    {
      id: 4,
      icon: admin,
      amount: response?.data?.data?.pumpAttendant?.totalCount || 0,
      name: "Pump Attendants",
    },
    // {
    //   id: 4,
    //   icon: admin,
    //   amount: response?.data?.data?.stationManager?.totalCount || 0,
    //   name: "Station Admin",
    // },
    // {
    // 	id: 5,
    // 	icon: totalTransactions,
    // 	amount: 20,
    // 	name: "Self Help",
    // },
    // {
    // 	id: 6,
    // 	icon: totalTransactions,
    // 	amount: CurrencyFormatter(Number("12000")),
    // 	name: "Feeds",
    // },
  ];

  const initialValues = {
    stationHQ: "",
    name: "",
    phoneNumber: "",
    location: {
      lga: "",
      state: "",
      latitude: "",
      longitude: "",
      address: "",
    },
    branchManager: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
    },
  };

  async function addNewBranchFunct(values: FormType) {
    try {
      const response = await AddHQBranch(values).unwrap();
      if (response) {
        SuccessNotification(response ? "Add successfully" : "");
        setShowNewModal(() => false);
      }
    } catch (error: any) {
      setShowNewModal(() => false);
      handleNotification(error);
    }
  }

  function handleToggle() {
    setShowNewModal((prevState) => !prevState);
  }

  return (
    <>
      {checkPermissions(auth?.user?.role || "", [
        PERMISSION.SYSTEM_ADMIN,
        PERMISSION.SUBADMIN,
      ]) && <SuperAdminDashboard />}
      {checkPermissions(auth?.user?.role || "", [PERMISSION.FEEDS]) && (
        <FeedsDashboard />
      )}
      {checkPermissions(auth?.user?.role || "", [PERMISSION.TRANSACTION]) && (
        <TransactionDashboard />
      )}
    </>
  );
};

export default Dashboard;
