import React, { ChangeEvent, useMemo, useState } from "react";
import { Button } from "src/components/Button";
import { CustomTabs } from "src/components/CustomTab";
import DepotDetails from "./DepotDetails";
import useCustomLocation from "src/hooks/useCustomLocation";
import {
	useGetSingleDepotQuery,
	useSuspendDepotMutation,
} from "src/api/manageHQApiSlice";
import { format } from "date-fns";
import DepotProducts from "./DepotProducts";
import { LoaderContainer } from "src/components/LoaderContainer";
import DepotStaff from "./DepotStaff";
import DepotDocuments from "./DepotDocuments";
import { FormModal } from "src/components/ModalComp";
import { TextArea } from "src/components/inputs";
import {
	ErrorNotification,
	SuccessNotification,
} from "src/helpers/helperFunction";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTE } from "src/helpers/Constant";

// TAB DATA FOR TABLE TAB
const tabData: { id: number; value: string; label: string }[] = [
	{ id: 1, value: "one", label: "Depot Profile" },
	{ id: 2, value: "two", label: "Depot Products" },
	{ id: 3, value: "three", label: "Documents" },
	{ id: 4, value: "four", label: "Staff" },
];

export default function SingleDepot() {
	const { slicedPath } = useCustomLocation();
	const [suspendDepotMutation, result] = useSuspendDepotMutation();
	const navigate = useNavigate();

	const getSingleDepotQuery = useGetSingleDepotQuery(slicedPath[2]);
	const [tab, setTab] = useState("one");

	const depotDetails = useMemo(
		() => getSingleDepotQuery?.data?.depot[0],
		[getSingleDepotQuery]
	);

	function handleTabChange(_: any, id: string) {
		setTab((prevState) => id);
	}

	// 	{
	// "status": {
	//     "status": "suspended",
	//     "reason": "They messed up"
	// }
	// }

	const [reason, setReason] = useState({ show: false, reason: "" });

	const suspendDepot = async () => {
		try {
			const result = await suspendDepotMutation({
				id: slicedPath[0],
				status: {
					status:
						depotDetails?.status?.status === "active"
							? "suspended"
							: "unsuspend",
					reason: reason?.reason,
				},
			}).unwrap();
			SuccessNotification(result?.message);
		} catch (error: any) {
			ErrorNotification(error?.data?.message);
		}
		setReason((prevState) => {
			return { ...prevState, show: !prevState.show };
		});
	};
	return (
		<section>
			{reason.show ? (
				<FormModal
					name="Reason for suspension"
					onClick={() =>
						setReason((prevState) => {
							return { ...prevState, show: !prevState.show };
						})
					}>
					<form
						className="mt-6 px-5"
						onSubmit={(e) => {
							e.preventDefault();
							suspendDepot();
						}}>
						<TextArea
							placeholder="Enter reason"
							onChange={(e) =>
								setReason((prevState) => {
									return { ...prevState, reason: e.target.value };
								})
							}
						/>
						<Button
							showModal={result.isLoading}
							disabled={reason.reason === "" ? true : false}
							className=" bg-primary text-white px-5 py-4 text-sm font-semibold w-full mt-6  rounded-3xl ml-auto "
							text="Submit"
							type="submit"
						/>
					</form>
				</FormModal>
			) : null}
			<LoaderContainer data={getSingleDepotQuery}>
				<article>
					<div className="flex flex-col items-center gap-y-6">
						<div>
							<img
								src={depotDetails?.logo?.url}
								className="h-[97px] w-[97px]  rounded-full object-cover"
								alt=""
							/>
						</div>
						<div className="text-2xl ">
							<h3 className="text-darkGray">Deport name </h3>

							<h6 className="font-bold text-black mt-3">
								{depotDetails?.name}
							</h6>
						</div>
						<div className="grid grid-cols-2 gap-x-3">
							<Button
								className=" bg-red-700 text-white px-5 py-2 text-sm font-semibold  md:w-[150px] rounded-3xl"
								text={
									depotDetails?.status?.status === "active"
										? "Suspend"
										: "Activate"
								}
								type="button"
								onClick={() =>
									setReason((prevState) => {
										return { ...prevState, show: !prevState.show };
									})
								}
							/>
							<Button
								className=" bg-primary text-white px-5 py-2 text-sm font-semibold  md:w-[150px] rounded-3xl"
								text="Edit info"
								type="button"
								onClick={() =>
									navigate(`/Depot/${depotDetails?._id}/edit`, {
										state: depotDetails,
									})
								}
							/>
						</div>
						<div>
							<div className="grid grid-cols-4 py-5">
								<SingleListComp
									name="Date added"
									value={format(
										new Date(depotDetails?.createdAt || null),
										"d-M-yyyy h:m:s"
									)}
								/>
								<SingleListComp
									name="Products"
									value={depotDetails?.totalProducts}
								/>
								<SingleListComp
									name="Request"
									value={depotDetails?.requests?.length}
								/>
								<SingleListComp name="Location" value={depotDetails?.address} />
								{/* <div className="flex flex-col justify-center items-center gap-y-4">
									<div className="w-[49px] h-[49px] rounded-full bg-emerald-900">
										<img src="" alt="" />
									</div>
									<div>
										<p>Date added</p>
										<h6>
											{format(
												new Date(depotDetails?.createdAt || null),
												"d-M-yyyy h:m:s"
											)}
										</h6>
									</div>
								</div> */}
							</div>
						</div>
						<div className="h-full w-full flex justify-between items-center py-6 px-6">
							<div>
								<CustomTabs
									value={tab}
									tabData={tabData}
									handleChange={handleTabChange}
								/>
							</div>
						</div>
					</div>
					{tab === "one" ? <DepotDetails depotDetails={depotDetails} /> : null}
					{tab === "two" ? (
						<DepotProducts products={depotDetails?.products} />
					) : null}
					{tab === "three" ? (
						<DepotDocuments legalDocs={depotDetails?.legalDocs} />
					) : null}
					{tab === "four" ? (
						<DepotStaff products={depotDetails?.depotStaff} />
					) : null}
				</article>
			</LoaderContainer>
		</section>
	);
}

const SingleListComp = ({ name, value }: { name: string; value: string }) => (
	<div className="flex flex-col justify-center items-center gap-y-4">
		<div className="w-[49px] h-[49px] rounded-full bg-emerald-900">
			<img src="" alt="" />
		</div>
		<div>
			<p className="text-sm">{name}</p>
			<h6 className="text-center mt-2">{value}</h6>
		</div>
	</div>
);
