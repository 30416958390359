import { HighlightOffOutlined, Print } from "@mui/icons-material";
import { splitByUpperCase } from "src/helpers/helperFunction";
import { Modal } from "./ModalComp";

const transactionPreviewFields: {
  [key: string]: string[];
} = {
  bank_transfer: [
    "created",
    "reference",
    "sendersName",
    "sendersAccountNumber",
    "sendersBank",
    "receiversName",
    "receiversSystemCode",
    "recieverWalletNumber",
    "receiverWalletBank",
    "amount",
    "balanceAfterTransaction",
    "category",
    "source",
    "type",
    "message",
    "status",
    "purpose",
  ],
  instant_wallet: [
    "created",
    "reference",
    "sendersName",
    "sendersSystemCode",
    "receiversName",
    "receiversSystemCode",
    "product",
    "instantWalletCode",
    "amount",
    "balanceAfterTransaction",
    "recieverWalletNumber",
    "category",
    "source",
    "type",
    "message",
    "status",
    "purpose",
  ],
  withdrawal: [
    "created",
    "reference",
    "sendersName",
    "sendersSystemCode",
    "receiversName",
    "receiversAccountNumber",
    "receiversBank",
    "amount",
    "balanceAfterTransaction",
    "category",
    "source",
    "type",
    "message",
    "status",
    "purpose",
  ],
  referral_bonus: [
    "created",
    "reference",
    "accountName",
    "accountSystemCode",
    "amount",
    "nameOfPersonReferred",
    "systemCodeOfPersonReferred",
    "balanceAfterReferralBonus",
    "category",
    "source",
    "type",
    "message",
    "status",
    "purpose",
  ],
  premium_feature: [
    "created",
    "reference",
    "branchName",
    "branchSystemCode",
    "amount",
    "nameOfFeatureAdded",
    "balanceAfterFeatureWasAdded",
    "category",
    "source",
    "type",
    "message",
    "status",
    "purpose",
  ],
};

export default function ReceiptCard(props: any) {
  const { data, setShowModal } = props;
  const { previewData } = data;
  const fields = transactionPreviewFields[previewData?.categoryType] || null;

  return (
    <Modal>
      <div className="absolute w-full h-full right-0 top-0 bg-[rgba(0,0,0,0.5)] flex justify-center items-center">
        <div className="w-[70%] mx-auto flex flex-col justify-center rounded-[20px] pb-10 bg-white">
          <div className="w-full px-10 pt-2 pb-2 mt-2 font-bold text-xl text-[#002E66] flex justify-between items-center">
            <h1>Transaction Details</h1>
            <button onClick={() => setShowModal(false)} disabled={false}>
              <HighlightOffOutlined
                fontSize="large"
                className="text-black cursor-pointer"
              />
            </button>
          </div>
          <div>
            <hr />
          </div>

          <div className="w-full h-full rounded-lg text-[14px] py-6 max-h-[70vh] overflow-y-auto">
            <div className="grid grid-cols-2 gap-x-1 gap-y-7 py-4 md:gap-x-2 text-start px-4 lg:px-16">
              {fields ? (
                fields.map((field) => (
                  <div key={field}>
                    <h2 className="text-black capitalize">
                      {splitByUpperCase(field)}
                    </h2>
                    <span className="block  w-20 my-0.5 rounded-lg"></span>
                    <h2 className="text-[#002E66]">{previewData[field]}</h2>
                  </div>
                ))
              ) : (
                <>
                  {Object.entries(previewData)?.map((dt: any) => {
                    console.log(dt);
                    return (
                      <div key={dt.id}>
                        <h2 className="text-black capitalize">
                          {splitByUpperCase(dt[0])}
                        </h2>
                        <span className="block  w-20 my-0.5 rounded-lg"></span>
                        <h2 className="text-[#002E66]">{dt[1]}</h2>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
