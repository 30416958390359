//FETCH_ALL_USER

import { API_ROUTE, RTKTAG } from "src/helpers/Constant";
import { apiSlice } from "./apiSlice";
import { providesTagList } from "src/helpers/helperFunction";

export const manageUserAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllUser: builder.query({
      query: (params) =>
        ({
          url: `${API_ROUTE.FETCH_ALL_USERS}?orderBy=createdAt:desc`,
          method: "GET",
          params,
        } as any),
      providesTags: (result) =>
        providesTagList(result?.profile?.data, RTKTAG.USERS) as any,
    }),
    exportAllUser: builder.query({
      query: () => API_ROUTE.FETCH_ALL_USERS,
      providesTags: (result) =>
        providesTagList(result?.profile?.data, RTKTAG.USERS) as any,
    }),
    fetchUser: builder.query({
      query: (id) => `${API_ROUTE.FETCH_ALL_USER}/${id}`,
      providesTags: (result) =>
        providesTagList(result?.profile?.data, RTKTAG.USERS) as any,
    }),
    fetchUserReferrals: builder.query({
      query: (id) => `${API_ROUTE.FETCH_ALL_USER_REFERRALS}?user=${id}`,
      providesTags: (result) =>
        providesTagList(result?.profile?.data, RTKTAG.USERS) as any,
    }),
    deactivateUser: builder.mutation({
      query: ({ id, ...body }) =>
        ({
          url: `${API_ROUTE.FETCH_ALL_USER}/${id}`,
          method: "PATCH",
          body,
        } as any),
      invalidatesTags: (result) =>
        providesTagList(result?.profile?.data, RTKTAG.USERS) as any,
    }),
    updateStationUser: builder.mutation({
      query: ({ id, ...body }) =>
        ({
          url: `${API_ROUTE.UPDATE_STATION_USER}/${id}`,
          method: "PATCH",
          body,
        } as any),
      invalidatesTags: [{ type: RTKTAG.MANAGE_HQ }],
    }),
    updateUser: builder.mutation({
      query: ({ id, ...body }) =>
        ({
          url: `${API_ROUTE.UPDATE_USER}/${id}`,
          method: "PATCH",
          body,
        } as any),
      invalidatesTags: [{ type: RTKTAG.MANAGE_HQ }],
    }),
    updateBranchDetails: builder.mutation({
      query: ({ id, ...body }) =>
        ({
          url: `${API_ROUTE.UPDATE_BRANCH}/${id}`,
          method: "PATCH",
          body,
        } as any),
      invalidatesTags: [{ type: RTKTAG.MANAGER_BRANCH }],
    }),
  }),
});

export const {
  useUpdateBranchDetailsMutation,
  useUpdateUserMutation,
  useFetchAllUserQuery,
  useFetchUserQuery,
  useExportAllUserQuery,
  useUpdateStationUserMutation,
  useDeactivateUserMutation,
  useFetchUserReferralsQuery,
} = manageUserAPISlice;
