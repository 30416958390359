import React, { useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { TableLoader } from "src/components/LoaderContainer";
import { CurrencyFormatter, truncateString } from "src/helpers/helperFunction";
import { useDebounce } from "src/hooks/useDebounce";
import useHandleRowClick from "src/hooks/useHandleRowClick";
import { Button } from "@mui/material";
import { CSVLink } from "react-csv";
import { SearchInput } from "src/components/inputs";
import ViewWalletComp from "src/components/ViewWalletComponent";
import { useFetchUserReferralsQuery } from "src/api/manageUserApi";
import { ReferralInfo, ReferralInfoArray } from "src/helpers/alias";

// Define the headers based on the expected data from the ReferralInfo type
const headCells = [
  { id: "firstName", minWidth: 170, label: "First Name" },
  { id: "lastName", minWidth: 170, label: "Last Name" },
  { id: "email", minWidth: 170, label: "Email" },
  { id: "createdAt", minWidth: 170, label: "Date Created" },
  { id: "status", minWidth: 170, label: "Account Status" },
  { id: "phoneNumber", minWidth: 170, label: "Phone Number" },
  { id: "referralCode", minWidth: 170, label: "Referral Code" },
  { id: "emailVerifiedAt", minWidth: 170, label: "Email Verified At" },
  { id: "updatedAt", minWidth: 170, label: "Last Updated" },
];

export default function UserReferalInfo({
  id,
  referralCode,
}: {
  id: string;
  referralCode: string;
}) {
  const { showModal, setShowModal, handleRowClick } = useHandleRowClick(fn);
  const [filterValue, setFilterValue] = useState<string>("");
  const { debouncedValue } = useDebounce(filterValue, 700);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [transactionData, setTransactionData] = useState<any>({});

  const allReferralsResult = useFetchUserReferralsQuery(id);

  const handledAPIResponse = useMemo(() => {
    const referrals = allReferralsResult?.currentData?.referrals || [];
    return referrals.map((cur: ReferralInfo) => ({
      firstName: cur.firstName,
      lastName: cur.lastName,
      email: cur.email,
      createdAt: format(new Date(cur.createdAt), "yyyy-MM-dd"),
      status: cur.accountStatus.status,
      phoneNumber: cur.phoneNumber,
      referralCode: cur.referralCode,
      emailVerifiedAt: cur.emailVerifiedAt
        ? format(new Date(cur.emailVerifiedAt), "yyyy-MM-dd")
        : "N/A",
      updatedAt: format(new Date(cur.updatedAt), "yyyy-MM-dd"),
    }));
  }, [allReferralsResult]);

  function fn(data: any) {
    setTransactionData(data);
    setShowModal((prev) => !prev);
  }

  return (
    <article>
      <div className="mt-6">
        <div className="w-full flex justify-between items-center">
          <h2 className="text-[24px] text-center font-bold">Referrals</h2>
          <div className="w-[109px] h-11">
            {/* CSV Export functionality can be added here if needed */}
          </div>
        </div>
        <div className="h-full w-full bg-white mt-6 shadow-lg rounded-t-lg">
          <div className="h-full w-full flex justify-between items-center py-6 px-6">
            <div className="flex min-w-64 h-11 max-w-[562px] items-center gap-2 rounded-[15px] border-2 border-[#D0D5DD] bg-[#D9D9D9] px-[18px]">
              <SearchInput
                name="branch-search"
                placeholder="Search"
                value={filterValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFilterValue(e.target.value);
                }}
              />
            </div>
            <div className="ml-auto flex gap-4 h-full">
              <DatePicker
                className="border border-gray-200 rounded-lg px-2 py-2"
                placeholderText="Start date"
                selected={startDate}
                onChange={setStartDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <DatePicker
                className="border border-gray-200 rounded-lg px-2 py-2"
                selected={endDate}
                placeholderText="End date"
                onChange={setEndDate}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
          </div>
          <TableLoader
            data={allReferralsResult}
            tableData={handledAPIResponse || []}
          >
            <ViewWalletComp
              rows={handledAPIResponse || []}
              headCells={headCells}
              handleRowClick={handleRowClick}
              handleChangePage={() => {}}
              paginationData={{
                totalPage: allReferralsResult?.currentData?.totalPages,
                limit: allReferralsResult?.currentData?.limit,
                page: allReferralsResult?.currentData?.page,
              }}
            />
          </TableLoader>
        </div>
      </div>
    </article>
  );
}
