import { Fragment, ReactElement, useEffect, useMemo } from "react";
import React, { useState } from "react";
import { Button } from "src/components/Button";
import useHandleRowClick from "src/hooks/useHandleRowClick";
import Users from "src/assets/img/attendant.svg";
import ReceiptCard from "src/components/ReceiptCard";
import { SelectInput, SelectType } from "src/components/SelectInput";
import { useExportAllTransactionsQuery } from "src/api/transactionsApiSlice";
import { CustomTabs } from "src/components/CustomTab";
import { CSVLink } from "react-csv";
import { SearchInput } from "src/components/inputs";
import { useDebounce } from "src/hooks/useDebounce";
import walletBtn from "src/assets/img/walletbtn.svg";
import Attendant from "src/assets/img/Attendanticon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UserTransactions from "./UserTransactions";
import StationTransactions from "./StationTransactions";
import { cardBtnType } from "src/helpers/alias";
import { CardButton } from "src/components/Card";

const transactionTabData: cardBtnType[] = [
  {
    id: 1,
    icon: Users,
    name: "Users",
  },
  {
    id: 3,
    icon: Attendant,
    name: "Station Branches",
  },
  {
    id: 2,
    icon: walletBtn,
    name: "Station Hq",
  },
];
export interface HeadCellTypes {
  id: string;
  label: string;
  numeric?: boolean | null;
  minWidth: number;
  amount?: string | number;
  type?: string;
  status?: string | ReactElement | any;
  referenceId?: string | number;
  doneby?: string;
}

export const headCells: readonly HeadCellTypes[] = [
  {
    id: "created",
    minWidth: 170,
    label: "Transaction Date",
  },
  {
    id: "name",
    minWidth: 170,
    label: "Station Name",
  },
  {
    id: "amount",
    minWidth: 170,
    label: "Transaction Amount (N)",
  },
  {
    id: "walletId",
    minWidth: 170,
    label: "Wallet ID",
  },
  {
    id: "referenceId",
    minWidth: 170,
    label: "Reference ID",
  },

  {
    id: "category",
    minWidth: 170,
    label: "Category",
  },
  {
    id: "source",
    minWidth: 170,
    label: "Source",
  },
  {
    id: "type",
    minWidth: 170,
    label: "Type",
  },
  {
    id: "doneby",
    minWidth: 170,
    label: "Done By",
  },
  {
    id: "status",
    minWidth: 170,
    label: "Status",
  },
];

// TRANSACTION TABLE FILTER INFORMATION
export const filterData: SelectType[] = [
  { id: 1, value: "available_balance", label: "Available balance " },
  { id: 7, value: "in_app_transfer", label: "App Transfer" },
  { id: 13, value: "bank_transfer", label: "Bank Transfer" },
  { id: 6, value: "credit", label: "Credit" },
  { id: 8, value: "cash", label: "Cash" },
  { id: 9, value: "debit", label: "Debit" },
  { id: 16, value: "fuel_purchase", label: "Fuel Purchase" },
  { id: 15, value: "instant_wallet", label: "Instant Wallet" },
  { id: 3, value: "pending", label: "Pending" },
  { id: 10, value: "premium_feature", label: "Premium Feature" },
  { id: 4, value: "referral_bonus", label: "Referral Bonus" },
  { id: 14, value: "reversed", label: "Reversed" },
  { id: 12, value: "remit", label: "Remit" },
  { id: 5, value: "successful", label: "Successful" },
  { id: 2, value: "user_transfer", label: "User transfer" },
  { id: 11, value: "withdrawal", label: "Withdrawal" },
];

// TAB DATA FOR TABLE TAB
const tabData: { id: number; value: string; label: string }[] = [
  { id: 1, value: "one", label: "Users" },
  { id: 2, value: "two", label: "Station branch" },
];

const Transactions = () => {
  const [cardName, setCardName] = useState<any>({
    tab: "users",
    who: "users",
    for: "users",
  });
  const { showModal, setShowModal, handleRowClick } = useHandleRowClick(fn);
  const [startDate, setStartDate] = useState<Date | any>(null);
  const [endDate, setEndDate] = useState<Date | any | number>(null);
  const [filterValue, setFilterValue] = useState<string>("");

  const { debouncedValue: debouncedEndDate } = useDebounce(endDate, 700);
  const { debouncedValue: debouncedStartDate } = useDebounce(startDate, 700);
  const { debouncedValue } = useDebounce(filterValue, 700);

  const [info, setInfo] = useState({
    page: 0,
    source: "",
  });
  // const [value, setValue] = useState({
  //   tab: "one",
  // });

  const [transactionData, setTransactionData] = useState<{}>({});
  const [transactionType, setTransactionType] = useState<any>("");

  function fn(data: { [index: string]: string | number }) {
    setTransactionType(data?.categoryType);
    setTransactionData(data);
    setShowModal((prev) => !prev);
  }
  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
    setFilterValue("");
    setInfo({
      page: 0,
      source: "",
    });
  }, [cardName]);

  // HANDLE CHANGE FOR TABLE TAB
  const handleSelectChange = (event: { target: { value: string } }) => {
    setInfo((prev) => {
      return { ...prev, source: event.target.value };
    });
  };

  const exportTransactionResult = useExportAllTransactionsQuery({
    ...{ ...info, ...cardName },
  });

  // HANDLE TAB CHANGE
  const handleTabChange = (newValue: string) => {
    if (newValue === "users") {
      setCardName({
        tab: "users",
        who: "users",
        for: "users",
      });
    }
    if (newValue === "station hq") {
      setCardName({
        tab: "station hq",
        who: "stationHq",
        for: "station_hq",
      });
    }
    if (newValue === "station branches") {
      setCardName({
        tab: "station branches",
        who: "stationBranch",
        for: "station_branch",
      });
    }
  };

  // HANDLE CHANGE FOR PAGINATION
  const handleChangePage = (event: unknown, newPage: number) => {
    setInfo((prev) => {
      return { ...prev, page: newPage };
    });
  };

  // HANDLE DATA EXPORT TO CSV

  const handleExportToCSV = useMemo(
    () => exportTransactionResult?.currentData?.transactions?.data,
    [exportTransactionResult]
  );
  console.log(cardName?.tab);
  return (
    <section>
      <article>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4  py-3">
          <>
            {transactionTabData.map((dt) => (
              <Fragment key={dt.id}>
                <CardButton
                  name={dt.name}
                  icon={dt.icon}
                  link={dt.link}
                  height={"70px"}
                  activeBtn={cardName.tab}
                  onClick={() => {
                    console.log(dt.name);
                    handleTabChange(dt.name?.toLowerCase());
                  }}
                  // showCard={cardName}
                />
              </Fragment>
            ))}
          </>
        </div>
        <div className=" mt-6 ">
          <div className="w-full flex justify-between items-center gap-2 flex-wrap">
            <SearchInput
              name="branch-search"
              placeholder="Search"
              value={filterValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const target = e.target;
                setFilterValue(() => target.value);
              }}
            />

            <div className=" ml-auto flex gap-4 h-full  flex-wrap">
              <DatePicker
                className="  min-w-48  h-11  max-w-[562px] w-32 bg-transparent border border-gray-200 rounded-lg px-2 py-2"
                placeholderText="Start date"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <DatePicker
                className="w-32 min-w-48  h-11  max-w-[562px]  bg-transparent border border-gray-200 rounded-lg px-2 py-2"
                selected={endDate}
                placeholderText="End date"
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />

              <SelectInput
                tabData={filterData}
                filteredValue={info.source}
                onChange={handleSelectChange}
              />

              <div className="w-[109px]  h-11">
                <CSVLink
                  filename="transaction_data.csv"
                  data={handleExportToCSV ?? []}
                >
                  <Button
                    text="Export"
                    className="h-full w-full font-bold bg-[#D0D5DD] rounded-lg hover: text-[#002E66] flex items-center justify-center"
                    type="button"
                    showIcon={false}
                    onClick={() => console.log("add branch")}
                  />
                </CSVLink>
              </div>
            </div>
          </div>
          <div className="h-full  w-full bg-white mt-6 shadow-lg rounded-t-lg">
            {/* <div className="h-full w-full flex justify-between items-center py-6 px-6">
              <div>
                <CustomTabs
                  value={value.tab}
                  tabData={tabData}
                  handleChange={handleTabChange}
                />
              </div>
            </div> */}

            {cardName?.tab === "users" ? (
              <UserTransactions
                headCells={headCells}
                handleRowClick={handleRowClick}
                handleChangePage={handleChangePage}
                debouncedValue={debouncedValue}
                info={info}
                startDate={debouncedStartDate}
                endDate={debouncedEndDate}
              />
            ) : null}

            {cardName?.tab === "station branches" ? (
              <StationTransactions
                handleRowClick={handleRowClick}
                handleChangePage={handleChangePage}
                debouncedValue={debouncedValue}
                info={info}
                value={cardName}
                startDate={debouncedStartDate}
                endDate={debouncedEndDate}
              />
            ) : null}

            {cardName?.tab === "station hq" ? (
              <StationTransactions
                headCells={headCells}
                handleRowClick={handleRowClick}
                handleChangePage={handleChangePage}
                debouncedValue={debouncedValue}
                info={info}
                value={cardName}
                startDate={debouncedStartDate}
                endDate={debouncedEndDate}
              />
            ) : null}
          </div>
        </div>
        {showModal ? (
          <ReceiptCard
            transactionType={transactionType}
            data={transactionData}
            setShowModal={setShowModal}
          />
        ) : null}
      </article>
    </section>
  );
};

export default Transactions;
