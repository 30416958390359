import { API_ROUTE, RTKTAG } from "src/helpers/Constant";
import { apiSlice } from "./apiSlice";
import { providesTagList } from "src/helpers/helperFunction";

export const manageHqAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllHQ: builder.query({
      query: (params) => {
        return {
          url: `${API_ROUTE.GET_ALL_HQ}?orderBy=createdAt:desc`,
          method: "GET",
          params,
        } as any;
      },
      providesTags: [
        { type: RTKTAG.MANAGER_BRANCH },
        { type: RTKTAG.MANAGE_HQ },
      ],
    }),
    exportAllHQ: builder.query({
      query: () =>
        `${API_ROUTE.GET_ALL_HQ}?orderBy=createdAt:desc&limit=10000000`,
    }),
    addNewHQ: builder.mutation({
      query: (body): any => ({
        url: API_ROUTE.ADD_NEW_HQ,
        method: "POST",
        body,
      }),
      invalidatesTags: (result) =>
        providesTagList(result?.hqProfile?.data, RTKTAG.MANAGE_HQ) as any,
    }),
    fetchSingleHQ: builder.query({
      query: ({ id, status }) => `${API_ROUTE.FETCH_SINGLE_HQ}/${id}`,
    }),
    changeStatus: builder.mutation({
      query: ({ id, ...body }): any => ({
        url: `${API_ROUTE.SUSPEND_HQ}/${id}`,
        method: "PATCH",
        body: { ...body },
      }),
      invalidatesTags: (result) => [{ type: RTKTAG.MANAGE_HQ }],
    }),
    getDepotList: builder.query({
      query: (): any => `${API_ROUTE.DEPOT}`,

      providesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
    suspendDepot: builder.mutation({
      query: ({ id, ...body }): any => ({
        url: `${API_ROUTE.DEPOT}/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
    createDepot: builder.mutation({
      query: (body): any => ({
        url: `${API_ROUTE.DEPOT}`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
    updateDepot: builder.mutation({
      query: ({ id, ...body }): any => ({
        url: `${API_ROUTE.DEPOT}/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
    getSingleDepot: builder.query({
      query: (id) => `${API_ROUTE.DEPOT}/${id}`,
      providesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
  }),
});

export const {
  useFetchAllHQQuery,
  useAddNewHQMutation,
  useFetchSingleHQQuery,
  useExportAllHQQuery,
  useChangeStatusMutation,
  useCreateDepotMutation,
  useGetDepotListQuery,
  useGetSingleDepotQuery,
  useSuspendDepotMutation,
  useUpdateDepotMutation,
} = manageHqAPISlice;
