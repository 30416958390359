import { useFormik } from "formik";
import React, { useState } from "react";
import { useCreateDepotMutation } from "src/api/manageHQApiSlice";
import { Button } from "src/components/Button";
import {
	ErrorNotification,
	SuccessNotification,
	convert2base64,
} from "src/helpers/helperFunction";
import * as yup from "yup";
import { DepotManageInformation } from "./components/ManageDepotInformation";
import { DepotDocument } from "./components/DepotDocuments";
import { DepotInformation } from "./components/DepotInformation";

export default function CreateDepot() {
	const [step, setStep] = useState(0);
	const [createDepotMutation] = useCreateDepotMutation();
	const formik = useFormik({
		initialValues: {
			email: "",
			phoneNumber: "",
			firstName: "",
			lastName: "",
			password: "",
			depot: {
				logo: "",
				name: "",
				email: "",
				phoneNumber: "",
				address: "",
				state: "",
				legalDocs: {
					certificateOfIncorporation: "",
					taxIdentificationNumber: "",
					certificateToOperateFuelStation: "",
				},
			},
		},
		validateOnChange: true,
		validateOnBlur: true,
		validationSchema: [
			yup.object().shape({
				email: yup.string().label("Email").required(),
				phoneNumber: yup.string().label("Phone number").required(),
				firstName: yup.string().label("First name").required(),
				lastName: yup.string().label("Last name").required(),
				password: yup.string().label("Password").required(),
			}),
			yup.object().shape({
				depot: yup.object().shape({
					email: yup.string().label("Email").required(),
					phoneNumber: yup.string().label("Phone number").required(),
					name: yup.string().label("Depot name").required(),
					address: yup.string().label("Address").required(),
					logo: yup.string().label("Logo").required(),
					legalDocs: yup.object().shape({
						certificateOfIncorporation: yup
							.string()
							.label("certificate Of Incorporation")
							.required(),
						taxIdentificationNumber: yup
							.string()
							.label("tax identification Number")
							.required(),
						certificateToOperateFuelStation: yup
							.string()
							.label("tax Identification Number")
							.required(),
					}),
				}),
			}),
		][step],

		onSubmit: async (value: any) => {
			if (step === 2) {
				try {
					const response = await createDepotMutation(value).unwrap();

					SuccessNotification(response?.data?.message);
				} catch (error: any) {
					ErrorNotification(error?.data?.message);
				}
			} else {
				setStep((prevState) => prevState + 1);
			}
		},
	});

	const uploadSelfImage = async (event: any) => {
		let images: any[] = [];

		const { name, files } = event.target;

		const file = files[0];

		if (images?.length > 0) {
			return;
		}

		return [...images, { [name]: await convert2base64(file) }];
	};

	async function uploadLogo(event: { target: { name: string } }) {
		const name = event.target.name;
		const logoImage = await uploadSelfImage(event);

		if (name === "logo") {
			formik.setFieldValue(
				"depot.logo",
				logoImage?.find((image) => image)[event.target.name]
			);
		} else {
			formik.setFieldValue(
				`depot.legalDocs.${[event.target.name]}`,
				logoImage?.find((image) => image)[event.target.name]
			);
		}
	};

	return (
		<section>
			<article className="w-full h-full py-4 shadow-lg">
				<div className=" text-white py-10 px-3 gap-x-4 flex justify-center w-3/4 ">
					<span
						className={`${
							step === 0 ? "text-primary font-bold" : "text-darkgray"
						}`}>
						Managers Info
					</span>
					<span
						className={`${
							step === 1 ? "text-primary font-bold" : "text-darkgray"
						}`}>
						Depo info
					</span>
					<span
						className={`${
							step === 2 ? "text-primary font-bold" : "text-darkgray"
						}`}>
						Upload documents
					</span>
				</div>
				<div className=" h-full  lg:w-3/4  mx-auto">
					<form
						className="w-full px-4 lg:px-0 lg:w-3/4 "
						onSubmit={formik.handleSubmit}>
						{step === 0 ? <DepotManageInformation formik={formik} /> : null}
						{step === 1 ? (
							<DepotInformation formik={formik} uploadLogo={uploadLogo} />
						) : null}
						{step === 2 ? (
							<DepotDocument formik={formik} uploadLogo={uploadLogo} />
						) : null}

						<div className="flex justify-between items-center py-4 gap-x-4">
							{step > 0 ? (
								<Button
									text="Prev"
									className="h-full w-full font-bold bg-[#D0D5DD] py-4 rounded-full hover: text-[#002E66] flex items-center justify-center"
									type="button"
									onClick={() => setStep((prevState) => prevState - 1)}
								/>
							) : null}
							<Button
								text={step === 2 ? "Submit" : "Next"}
								className="h-full w-full rounded-full font-bold bg-primary text-white   flex items-center justify-center px-10 py-4"
								type="submit"
							/>
						</div>
					</form>
				</div>
			</article>
		</section>
	);
}

export const ImagePreview = ({ image }: { image: string }) => {
	return (
		<div className="w-3/4 h-56 my-4 mx-auto">
			<img src={image} className="w-full h-full  object-contain" alt="" />
		</div>
	);
};



// A simple way to handle multiple  file upload and conversion to based 64   in your react app.
// handle 4 to 5 file image/document, esp when the backend is expecting a based 64 format of the file.
