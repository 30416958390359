import { DocumentUpload } from "src/components/Upload";
import { ImagePreview } from "../CreateDepot";

export const DepotDocument = ({
	formik,
	uploadLogo,
}: {
	formik: any;
	uploadLogo: (arg: any) => void;
}) => {
	return (
		<article>
			<div className="flex-col w-full items-center justify-between overflow-x-auto ">
				<div className="w-full mt-4">
					<DocumentUpload
						name="certificateOfIncorporation"
						text="Click here to upload Certificate of incorporation"
						onChange={uploadLogo}
					/>
					{formik.values?.depot.legalDocs.certificateOfIncorporation ? (
						<ImagePreview
							image={formik.values?.depot.legalDocs.certificateOfIncorporation}
						/>
					) : null}
				</div>
				<div className="w-full my-10">
					<DocumentUpload
						name="taxIdentificationNumber"
						text="Click here to upload Tax identification number"
						onChange={uploadLogo}
					/>
					{formik.values?.depot.legalDocs.taxIdentificationNumber ? (
						<ImagePreview
							image={formik.values?.depot.legalDocs.taxIdentificationNumber}
						/>
					) : null}
				</div>
				<div className="w-full mt-4">
					<DocumentUpload
						name="certificateToOperateFuelStation"
						text="Click here to upload Certificate to operate fuel station"
						onChange={uploadLogo}
					/>
					{formik.values?.depot.legalDocs.certificateToOperateFuelStation ? (
						<ImagePreview
							image={
								formik.values?.depot.legalDocs.certificateToOperateFuelStation
							}
						/>
					) : null}
				</div>
			</div>
		</article>
	);
};
